.why-choose {
  max-width: 50em;
  margin: 0 auto;
  padding: 5rem 0;
  h4 {
    text-align: center;
    margin-bottom: 2rem;
  }
  ul {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: $laptop) {
  .why-choose {
    max-width: 28em;
    margin: 0 auto;
    padding: 0 0.5rem;
    h4 {
      text-align: left;
    }
  }
}