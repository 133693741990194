/* lfh-revamp.scss */
@charset "utf-8";
@import "@abw/badger-ui/dist/styles/helpers/all";
@import "helpers/all";
@import "config/all";
@import "@abw/badger-ui/dist/styles/config/all";
@import "@abw/badger-ui/dist/styles/utils/all";
@import "@abw/badger-ui/dist/styles/components/all";
@import "site/all";
@import "components/all";
@import "entities/all";

:root {
  --toastify-color-dark:    #{$dark};
  --toastify-color-info:    #{$blue};
  --toastify-color-success: #{$green};
  --toastify-color-warning: #{$orange};
  --toastify-color-error:   #{$red};
}

.Toastify__toast {
  border: 2px solid rgba($white, 0.5);
  box-shadow: 2px 2px 5px $black;
  &.Toastify__toast--success {
    border-color: $green;
  }
  &.Toastify__toast--warning {
    border-color: $orange;
  }
  &.Toastify__toast--error {
    border-color: $red;
  }
}

.carousel .slide img {
  width: 100%;
  height: 100%;
  // aspect-ratio: 1/1;
}