//----------------------------------------------------------------------------
// Colors
//----------------------------------------------------------------------------
// The primary hue is used to set text and headline colors and other general
// styling.  The secondary hue is used for elements with a contrasting colour.
$primary-hue:    30 !default;
$secondary-hue: 180 !default;
$tertiary-hue:  240 !default;

$primary-mid:   hsl($primary-hue, 65%, 65%);
$primary-dark:  hsl($primary-hue, 55%, 55%);

// color palette
// $black:    hsl($primary-hue, 20%, 10%) !default;
$black:   #000;
$dark:    #272727;
$grey:    #868892;
$white:   #fff;
$red:     #E6334C;
$orange:  #F49D00;
$yellow:  #EFD600;
$green:   #93C01F;
$blue:    #3DA2DB;
$violet:  #836FCB;

$red-light:     lfh-light-color($red);
$orange-light:  lfh-light-color($orange);
$yellow-light:  lfh-light-color($yellow);
$green-light:   lfh-light-color($green);
$blue-light:    lfh-light-color($blue);
$violet-light:  lfh-light-color($violet);
$grey-light:    lfh-light-color($grey);

$red-dark:      lfh-dark-color($red);
$orange-dark:   lfh-dark-color($orange);
$yellow-dark:   lfh-dark-color($yellow);
$green-dark:    lfh-dark-color($green);
$blue-dark:     lfh-dark-color($blue);
$violet-dark:   lfh-dark-color($violet);
$grey-dark:     lfh-dark-color($grey);


$base-colors: (
  'red':            $red,
  'orange':         $orange,
  'yellow':         $yellow,
  'green':          $green,
  'blue':           $blue,
  'violet':         $violet,
  'grey':           $grey,
);

$colors: (
  'grey':           $grey,
  'red':            $red,
  'orange':         $orange,
  'yellow':         $yellow,
  'green':          $green,
  'blue':           $blue,
  'violet':         $violet,
  'red-light':      $red-light,
  'orange-light':   $orange-light,
  'yellow-light':   $yellow-light,
  'green-light':    $green-light,
  'blue-light':     $blue-light,
  'violet-light':   $violet-light,
  'grey-light':     $grey-light,
  'red-dark':      $red-dark,
  'orange-dark':   $orange-dark,
  'yellow-dark':   $yellow-dark,
  'green-dark':    $green-dark,
  'blue-dark':     $blue-dark,
  'violet-dark':   $violet-dark,
  'grey-dark':     $grey-dark,
);

$color-depths: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $name, $color in $colors {
  .#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
  .#{$name}-rgb:before {
    content: "#{$color}";
  }
}

$red-hue:    352;
$orange-hue: 39;
$yellow-hue: 54;
$green-hue:  77;
$blue-hue:   202;
$violet-hue: 253;

// extras for gradients in hire days
$orange-red-hue: 24;
$red-magenta-hue: 332;

// https://accessiblepalette.com/?lightness=98.2,93.9,85,76.2,67.4,57.8,48,40.2,31.8,24.9&e6334c=1,0&f49d00=1,0&efd600=1,0&93c01f=1,0&3da2db=1,0&836fcb=1,0&808080=0,0
$red-50:     #FFF8F8;
$red-100:    #FFE8E7;
$red-200:    #FFC7C5;
$red-300:    #FEA4A2;
$red-400:    #F88182;
$red-500:    #EE5661;
$red-600:    #D53147;
$red-700:    #B02D3C;
$red-800:    #892730;
$red-900:    #6B2227;
$orange-50:  #FFF9F2;
$orange-100: #FFEBD1;
$orange-200: #FFCB8B;
$orange-300: #FBAC3F;
$orange-400: #E49306;
$orange-500: #C07C0F;
$orange-600: #9D6613;
$orange-700: #825514;
$orange-800: #674413;
$orange-900: #513612;
$yellow-50:  #FFFAE7;
$yellow-100: #FFEEAA;
$yellow-200: #EED500;
$yellow-300: #D3BD0D;
$yellow-400: #B8A514;
$yellow-500: #9C8B17;
$yellow-600: #807218;
$yellow-700: #6B5F17;
$yellow-800: #544B16;
$yellow-900: #433B14;
$green-50:   #F9FBF1;
$green-100:  #EAF1D1;
$green-200:  #C9DD8E;
$green-300:  #A5C949;
$green-400:  #89B21F;
$green-500:  #74961F;
$green-600:  #607B1D;
$green-700:  #51661B;
$green-800:  #415119;
$green-900:  #343F16;
$blue-50:    #F7FAFD;
$blue-100:   #E4EFF9;
$blue-200:   #BBD8F1;
$blue-300:   #90C2E8;
$blue-400:   #5DACDF;
$blue-500:   #3A93C6;
$blue-600:   #3478A1;
$blue-700:   #2F6485;
$blue-800:   #284F68;
$blue-900:   #223E51;
$violet-50:  #FAF9FD;
$violet-100: #F0ECF9;
$violet-200: #D9D0EF;
$violet-300: #C3B5E6;
$violet-400: #AC9ADC;
$violet-500: #927ED1;
$violet-600: #7865B8;
$violet-700: #645598;
$violet-800: #4F4376;
$violet-900: #3F355C;
$grey-50:    #FAFAFA;
$grey-100:   #EDEDEF;
$grey-200:   #D3D4D8;
$grey-300:   #BBBCC1;
$grey-400:   #A2A4AC;
$grey-500:   #898B94;
$grey-600:   #70727A;
$grey-700:   #5D5F65;
$grey-800:   #4A4B50;
$grey-900:   #3A3B3F;
$color-shades: (
  'red-50':      $red-50,
  'red-100':     $red-100,
  'red-200':     $red-200,
  'red-300':     $red-300,
  'red-400':     $red-400,
  'red-500':     $red-500,
  'red-600':     $red-600,
  'red-700':     $red-700,
  'red-800':     $red-800,
  'red-900':     $red-900,
  'orange-50':   $orange-50,
  'orange-100':  $orange-100,
  'orange-200':  $orange-200,
  'orange-300':  $orange-300,
  'orange-400':  $orange-400,
  'orange-500':  $orange-500,
  'orange-600':  $orange-600,
  'orange-700':  $orange-700,
  'orange-800':  $orange-800,
  'orange-900':  $orange-900,
  'yellow-50':   $yellow-50,
  'yellow-100':  $yellow-100,
  'yellow-200':  $yellow-200,
  'yellow-300':  $yellow-300,
  'yellow-400':  $yellow-400,
  'yellow-500':  $yellow-500,
  'yellow-600':  $yellow-600,
  'yellow-700':  $yellow-700,
  'yellow-800':  $yellow-800,
  'yellow-900':  $yellow-900,
  'green-50':    $green-50,
  'green-100':   $green-100,
  'green-200':   $green-200,
  'green-300':   $green-300,
  'green-400':   $green-400,
  'green-500':   $green-500,
  'green-600':   $green-600,
  'green-700':   $green-700,
  'green-800':   $green-800,
  'green-900':   $green-900,
  'blue-50':     $blue-50,
  'blue-100':    $blue-100,
  'blue-200':    $blue-200,
  'blue-300':    $blue-300,
  'blue-400':    $blue-400,
  'blue-500':    $blue-500,
  'blue-600':    $blue-600,
  'blue-700':    $blue-700,
  'blue-800':    $blue-800,
  'blue-900':    $blue-900,
  'violet-50':   $violet-50,
  'violet-100':  $violet-100,
  'violet-200':  $violet-200,
  'violet-300':  $violet-300,
  'violet-400':  $violet-400,
  'violet-500':  $violet-500,
  'violet-600':  $violet-600,
  'violet-700':  $violet-700,
  'violet-800':  $violet-800,
  'violet-900':  $violet-900,
  'grey-50':     $grey-50,
  'grey-100':    $grey-100,
  'grey-200':    $grey-200,
  'grey-300':    $grey-300,
  'grey-400':    $grey-400,
  'grey-500':    $grey-500,
  'grey-600':    $grey-600,
  'grey-700':    $grey-700,
  'grey-800':    $grey-800,
  'grey-900':    $grey-900,
);


// $light-green: #C4DD86
// $light-orange: #F9C363
// $light-red: #FF7683

//$orange:  #ff8011 !default;
// $yellow:  #fdea23 !default;
// $green:   #8fc43f !default;
//$blue:    #5daff0 !default;
//$violet:  #7969AF !default;
$brown:   #A5673F !default;

$line-color: rgba($white, 0.2);

// text colours
$head-color: hsl($primary-hue, 60%, 10%) !default;
$text-color: hsl($primary-hue, 30%, 10%) !default;
$dark-back: $black;
$dark-text: rgba($white, 0.8);
$dark-head: $white;

// links
$link-color: $blue;
$link-hover: $orange;

$option-selected: $green;
$option-hover: $orange;
$option-hover: $orange;

$border-color: rgba($white, 0.2);

// colors for alerts and other general style representing different statuses
$info-color:      $blue;
$info-dark:       darkish-color($info-color);
$info-back:       $dark;
$info-text:       $white;
$info-head:       $info-color;
$info-edge:       $info-color;
$info-hover:      darkish-color($info-color);

$success-color:   $green;
$success-dark:    darkish-color($success-color);
$success-back:    $dark;
$success-text:    $white;
$success-head:    $success-color;
$success-edge:    $success-color;
$success-hover:   darkish-color($success-color);

$warning-color:   $orange;
$warning-dark:    darkish-color($warning-color);
$warning-back:    $dark;
$warning-text:    $white;
$warning-head:    $warning-color;
$warning-edge:    $warning-color;
$warning-hover:   darkish-color($warning-color);

$error-color:     $red;
$error-dark:      darkish-color($error-color);
$error-back:      $dark;
$error-text:      $white;
$error-head:      $error-color;
$error-edge:      $error-color;
$error-hover:     darkish-color($error-color);

$table-radius:          2px;
$table-radius-inner:    1px;
$table-shade:           $dark;
$table-edge:            $black;
$table-head-back:       $black;
$table-head-fore:       $white;
$table-spec-back:       $black !default;
$table-spec-fore:       $white !default;
$table-spec-border:     rgba($black, 0.5) !default;
$table-key-back:        $table-head-back !default;
$table-key-fore:        $table-head-fore !default;
$table-detail-border:   hsl($primary-hue, 40%, 80%) !default;
$table-detail-foot:     hsl($primary-hue, 60%, 70%) !default;
$table-stripe-back:     hsl($primary-hue, 50%, 97%) !default;
