ul.checklist li {
  list-style: none;
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5em;
  & >.icon {
    position: relative;
    top: 0.25em;
    margin-right: 0.5em;
  }
}
