.swatch {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

@each $name,$color in $color-shades {
  .swatch.#{$name} {
    background-color: $color;
    color: white;
    &[class*="-50"],
    &[class*="-100"],
    &[class*="-200"],
    &[class*="-300"],
    &[class*="-400"] {
      color: $black;
    }
    &[class*="-500"] {
      color: $white;
    }
  }
  .#{$name}-rgb:before {
    content: "#{$color}";
  }
}

.text-1 {
  color: var(--text-1) !important;
}
.text-2 {
  color: var(--text-2) !important;
}
.text-3 {
  color: var(--text-3) !important;
}
.text-4 {
  color: var(--text-4) !important;
}