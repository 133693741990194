$controls-width: 175px;

header.site {
  position: relative;
  padding-right: $controls-width;
  height: $header-height;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .logo {
    display: block;
    width: $controls-width;
    min-width: $controls-width;
    opacity: 0.9;
    height: $header-height - 20px;
    font-size: 0;
    line-height: 0;
    overflow: visible;
    &:hover {
      opacity: 1;
    }
    margin-left: -1px;
    img {
      height: 100%;
      margin: 10px 0;
    }
    &.home {
      img {
      //  height: 150px;
      }
    }
  }
  .controls {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: $controls-width;
    position: fixed;
    top: 0;
    right: calc(50% - 600px - 1.5rem);
    z-index: 99999;
    padding: 0 1rem 0.2rem 1rem;
    border-radius: 0 0 1em 1em;
    background-color: transparent;
    transition: background-color 0.5s ease-in-out;
    text-align: right;
    & > * {
      margin: 0 0.5rem;
      padding-top: 1.5rem;
      &:last-child {
        margin-right: 0;
        margin-right: 0.5rem;
      }
    }
    & > .control {
      padding-bottom: 1.5rem;
      position: relative;
      .icon {
        transition: color 0.2s ease-in-out;
      }
      &:hover {
        .icon {
          color: $option-hover !important;
        }
      }
      .count {
        cursor: pointer;
        position: absolute;
        bottom: 1.8rem;
        right: 0;
        width: 100%;
        text-align: center;
        font-size: $size-small;
        line-height: 1;
        font-weight: bold;
        color: $white;
        text-shadow: 0 0 10px $black;
      }
      .caption {
        font-size: $size-small;
        color: $white;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        min-width: 10rem;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &:hover .caption {
        opacity: 1;
      }
    }
  }
  .dropdown .trigger:hover .icon {
    color: $option-hover !important;
  }
  &.offscreen .controls {
    background-color: rgba($black, 0.8);
    &:hover {
      background-color: rgba($black, 0.9);
    }
  }
  .dropdown {
    .content {
      background-color: transparent;
      min-width: 12rem;
      border: 1px solid transparent;
      border-top-width: 0.5rem;
      border-top-color: transparent;
      // border-right-width: 0;
      border-radius: 0;
      box-shadow: 1px 2px 5px $black;
    }
    .menu {
      border-radius: 0;
      background-color: $dark;
      padding: 0.5rem;
      border: 1px solid $black;
      box-shadow: 2px 2px 12px rgba($black, 0.2);
      .item {
        background-color: $black;
        margin-bottom: 0.5rem;
        border: 2px solid $black;
        color: $white !important;
        &:last-child {
          margin-bottom: 0;
        }
        .icon {
          color: $white !important;
        }
        &:hover {
          border-color: $option-hover;
        }
      }
    }
  }
  .incomplete-order {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    // margin-top: -10rem;
    // margin-bottom: -2rem;
  }
}

@media only screen and (max-width: $desktop) {
  header.site {
    .controls {
      right: -0.5rem;
    }
  }
}
@media only screen and (max-width: $tablet) {
  header.site {
    .incomplete-order {
      bottom: 0rem;
    }
  }
}

@media only screen and (max-width: $mobile) {
  header.site {
    .trustpilot {
      display: none;
    }
    .incomplete-order {
      right: 0;
      bottom: 1rem;
      left: auto;
      transform: none;
    }
  }
}