.darkside {
  a {
    color: $blue;
    &.white {
      color: $white;
    }
    &:hover {
      color: $orange;
    }
  }
}

.dark, .darkside {
  h1, h2, h3, h4 {
    color: $dark-head;
  }
}

.dark {
  background-color: $dark-back;
  // color: $dark-text;
  color: $white;
}
.darkish {
  background-color: $dark;
}

.bg-white {
  color: $black;
  h1, h2, h3, h4 {
    color: $head-color;
  }
  h4.underline {
    border-bottom: 1px solid rgba($black, 0.1);
  }
}

//.alert.stripe {
//  background-color: $dark;
//}
//.alert .body {
//  background-color: $dark;
//}