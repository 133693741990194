$basket-add: $green;
$basket-added: $green-light;
$basket-hover: $option-hover;
$basket-selected: $option-selected;
$border-width: 5px;

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 1rem 0;
  justify-content: center;
  align-content: center;
}

.product-card {
  display: flex;
  flex-direction: column;
  background-color: $white;
  justify-content: center;
  color: $black;
  line-height: 1;
  padding: 0.25rem;
  transition: all 0.2s ease-in-out;
  .picture {
    position: relative;
    text-align: center;
    padding: 2rem 0 1rem 0;
    background-color: $white;
    flex-basis: 100%;
    .new-banner {
      position: absolute;
      bottom: 0.25rem;
      left: 0;
      width: 100%;
      background-color: $violet; //rgba($orange, 0.8);
      color: $black;
      text-align: center;
      font-size: $size-largish;
      padding: 0.25rem 0;
    }
    .new-sticker {
      position: absolute;
      bottom: 1.5rem;
      right: 25%;
      font-size: 5rem;
      transform: translateX(50%);
    }
  }
  .info {
    text-align: left;
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: 100%;
    padding-right: 0.5rem;
    h4 {
      color: $black;
      margin: 0;
      font-size: $size-medium;
      font-family: $font-medium;
      letter-spacing: 0;
    }
    h5 {
      color: $black;
      margin: 0;
      font-size: $size-smallish;
      font-family: $font-medium;
    }
  }
  .hire-info {
    text-align: left;
    position: absolute;
    bottom: 2.5rem;
    left: 0.3rem;
    width: 100%;
    padding-right: 0.5rem;
    font-size: $size-smallish;
  }
  div.price {
    text-align: left;
    display: flex;
    align-items: baseline;
    background-color: $dark;
    flex-basis: 100%;
    padding: 0.3rem 0 0 0.4rem;
    margin-right: 0.25rem;
    div.money {
      background-color: $dark;
      color: $white;
      font-size: $size-largish;
    }
    div.duration {
      color: $white;
      font-size: $size-small;
      margin-left: 0.5em;
    }
    &:hover div.duration {
      color: $white;
    }
  }
  &:hover {
    box-shadow: 0 0 0 $border-width $basket-hover;
  }
  &.selected {
    box-shadow: 0 0 0 $border-width $basket-selected;
  }
  &.selected button {
    // background-color: $green-light !important;
    background-color: $basket-added;
    // opacity: 0.7;
    &:hover {
      opacity: 1;
      // background-color: mix($basket-added, $white, 90%);
    }
  }
}

.price-add {
  padding: 0;
  display: flex;
  align-items: stretch;
  .price {
    text-align: left;
    flex-basis: 100%;
    display: flex;
    align-items: baseline;
    background-color: $dark;
    padding: 0.3rem 0 0 0.4rem;
    margin-right: 0.25rem;
    div.money {
      background-color: $dark;
      color: $white;
      font-size: $size-largish;
    }
    div.duration {
      color: $white;
      font-size: $size-small;
      margin-left: 0.5em;
    }
    &:hover div.duration {
      color: $white;
    }
  }
  .button,
  .button:focus {
    border: 1px solid $black;
    border-radius: 0;
    background-color: $basket-add;
    color: $black !important;
    text-shadow: none !important;
    border: 0 !important;
    box-shadow: none !important;
    .icon {
      filter: none !important;
    }
    &:hover {
      background-color: $basket-hover;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}

.product-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  //& > * {
  //  height: calc(600px - 0.5rem);
  //}
  .combo-panel {
    height: auto;
    .related {
      margin-bottom: 1rem;
    }
  }
  .product-panel {
    background-color: $dark;
    color: $white;
    border: 0.5rem solid $dark;
    overflow: auto;
    &.description,
    &.availability {
      grid-column: 1 / 3;
      height: auto;
    }
    &.features,
    &.compatible.solo,
    &.related.solo {
      height: auto;
    }
    &.pictures {
      background-color: $white;
      border-color: $white;
      .pictures-carousel .carousel {
        padding-bottom: 0;
        .nav {
          height: 100%;
        }
      }
    }
    div.pictures-price-button {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .price-add {
        font-size: 120%;
        .price {
          padding: 0.25rem 0.5rem;
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
        }
      }
    }
    &>.title {
      margin: 0;
      padding: 0.25rem 1rem 0;
    }
    .panel-body {
      padding: 0 1rem 1rem;
      &.scroll-y {
        overflow-y: scroll;
      }
      .scroll-x {
        text-align: center;
        overflow-x: scroll;
        padding-bottom: 1rem;
      }
    }
  }
  & > a {
    margin-bottom: 0;
    padding: 0;
  }
  .product-name {
    font-family: $font-light;
    line-height: 120%;
    margin: 0.5rem 0;
  }
  .features-overview {
    display: flex;
    align-items: flex-start;
    .features {
      flex: 1 0 30%;
      margin-right: 1rem;
    }
    .overview {
      flex: 2 0 65%;
    }
  }
  .overview-panes {
    display: flex;
    .description {
      flex: 2 0 60%;
      padding-right: 4em;
    }
    .pics {
      flex: 1 0 40%;
      margin: -1rem 0 0 0;
      padding: 0 1rem;
    }
  }
  table.product-features {
    width: 100%;
    border: 0;
    margin: 0;
    border-collapse:separate;
    border-spacing: 0 0.5em;
    color: $white;
    background-color: $dark;
    margin-bottom: -0.5rem;
    tr {
      border: 0;
      td, th {
        border: 0;
        padding: 0.25em 0.5em;
        background-color: $black;
      }
      th {
        background-color: $black;
      }
      td {
        text-align: right;
      }
      &:last-child {
        td, th {
          border-bottom: 0;
        }
      }
    }
  }
  .product-links {
    margin-top: 0.5rem;
    .product-link {
      background-color: $black;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 0.5rem;
      // border-bottom: 1px solid $smoke;
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
      img {
        margin: 0;
        width: 30px;
        height: 30px;
        // mix-blend-mode: multiply;
        background-color: $white;
      }
      .name {
        padding: 0.25em 0.5em;
        margin-left: 0.5rem;
        // font-size: $size-largish;
        color: $white;
      }
      &:hover {
        background-color: $blue;
        .name {
          color: $white;
        }
      }
    }
    &.solo {
      .panel-body {
        display: flex;
        flex-wrap: wrap;
        .product-link {
          flex-basis: 50%;
          flex-shrink: 0;
        }
      }
    }
  }
}

.pictures-carousel {
  position: relative;
  .carousel-item {
    margin: 0 4rem;
  }
  .nav {
    position: absolute;
    top: 0;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    color: $grey;
    &.active {
      color: $blue;
      &:hover {
      color: $orange;
      }
    }
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  .carousel.carousel-slider {
    padding-bottom: 4rem;
  }
  .carousel .control-dots {
    .dot {
      width: 12px;
      height: 12px;
      background-color: $grey;
      //box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.7);
      //border: 1px solid rgba($black, 0.5);
      box-shadow: none;
      &:hover {
        background-color: $orange;
      }
      &.selected {
        background-color: $green;
      }
    }
  }
}

.carousel {
  .carousel-status {
    font-size: $size-smaller;
    text-shadow: none;
    color: $blue;
  }
  .slide .legend {
    bottom: 0px;
    border-radius: 5px;
    padding: 0.5rem;
    bottom: 1rem;
    font-size: $size-smaller;
    opacity: 0.2;
    transition: opacity .35s ease-in-out;
  }
  &:hover {
    cursor: pointer;
    .slide .legend {
      opacity: 0.5;
    }
  }
}
.modal {
  max-height: 95vh;
}

@media only screen and (max-width: $desktop) {
  .product-info {
    .overview-panes {
      flex-direction: column-reverse;
      .description {
        padding-right: 0;
      }
      .pics {
        margin-bottom: 0;
      }
    }
    .basket-buttons {
      display: flex;
      padding-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: $laptop) {
  .product-title {
    font-size: $size-larger;
  }
  .product-info {
    //& > * {
    //  height: calc(500px - 0.5rem);
    //}
    .product-name {
      font-size: $size-larger;
    }
    .features-overview {
      flex-direction: column-reverse;
      .features {
        margin-right: 0;
        width: 100%;
      }
      .overview {
        width: 100%;
      }
    }
    .product-links.solo {
      .panel-body {
        display: block;
      }
    }
  }
}
@media only screen and (max-width: $tablet) {
  .product-title {
    font-size: $size-large;
  }
  .product-info {
    grid-template-columns: repeat(1, 1fr);
    & > * {
      height: auto;
    }
    .product-panel {
      &.features {
        grid-row: 3;
      }
      &.pictures {
        grid-row: 2;
      }
      &.description,
      &.availability {
        grid-column: 1 / 1;
      }
    }
    .product-name {
      font-size: $size-largish;
      font-family: $font-light;
//      font-weight: bold;
    }
  }
}
@media only screen and (max-width: $mobile) {
  .product-title {
    font-size: $size-largish;
  }
  .product-info {
    .basket-buttons {
      flex-wrap: wrap;
      .button {
        margin-right: 0;
      }
    }
  }
  .pictures-carousel {
    .carousel-item {
      margin: 0 1rem;
    }
  }

}

@media only screen and (max-width: 420px) {
  .product-info .product-panel div.pictures-price-button .price-add {
    display: block;
    .price {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
    .button {
      width: 100%;
    }
  }
}
