//.container {
//  max-width: $desktop;
//  margin: 0 auto;
//}

@media only screen and (min-width: $desktop) {
  .container {
    max-width: $desktop;
    margin: 0 auto;
  }
}

// media breakpoints
@media only screen and (max-width: $laptop) {
  .flex.stack-laptop {
    display: block !important;
    .mar-t-stack {
      margin-top: 1em;
    }
  }
  @include bar-stack('laptop');
}
@media only screen and (max-width: $tablet) {
  .flex.stack-tablet {
    display: block !important;
    .mar-t-stack {
      margin-top: 1em;
    }
  }
  @include bar-stack('tablet');
}
@media only screen and (max-width: $mobile) {
  @include bar-stack('mobile');
}

@media only screen and (max-width: calc($laptop + 32px)) {
  .container.laptop {
    margin-left:  1rem !important;
    margin-right: 1rem !important;
  }
}
@media only screen and (max-width: calc($tablet + 32px)) {
  .container.tablet {
    margin-left:  1rem !important;
    margin-right: 1rem !important;
  }
}
@media only screen and (max-width: calc($mobile + 32px)) {
  .container.mobile {
    margin-left:  1rem !important;
    margin-right: 1rem !important;
  }
}
