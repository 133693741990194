.label {
  background-color: $grey;
  color: $black;
}
@each $name,$color in $colors {
  .label.#{$name} {
    background-color: mix($color, $white, 60%);
    border-width: 2px;
    border-color: $color;
    color: $black;
    line-height: 130%;
    border-radius: 1em;
  }
}
