.countdown {
  text-align: center;
  margin-top: 2rem;
  .clock {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0.5rem 0;
    .countdown-separator {
      font-size: $size-largest;
      line-height: 0.9;
      margin: 0 0.2rem;
      // margin-top: 0.2em;
      color: $grey;
      animation: pulse 1s infinite;
    }
    .countdown-element {
      text-align: center;
      .number {
        font-size: $size-largest;
        line-height: 1;
        color: $green;
      }
      .caption {
        font-size: $size-small;
        line-height: 1.2;
        color: $grey;
      }
    }
  }
  &.warning .clock .countdown-element .number {
    color: $yellow;
  }
  &.danger .clock .countdown-element .number {
    color: $orange;
  }
  &.critical .clock .countdown-element .number {
    color: $red;
  }
  .overprompt {
    font-size: $size-largish;
    line-height: 1.2;
  }
  .underprompt {
    // font-size: $size-largish;
    line-height: 1.2;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    color: $smoke;
  }
  99% {
    opacity: 0.5;
  }
  100% {
    opacity: 1
  }
}

@media only screen and (max-width: $tablet) {
  .countdown {
    padding: 0 1rem;
  }
}
