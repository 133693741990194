.trustpilot {
  font-family:"Segoe UI","Helvetica Neue","Helvetica","Arial","sans-serif";
  font-size:12px;
  line-height:1;
  color: $white;
  a {
    color:#fff; text-decoration: none;
  }
  .tp-widget-rating {
    .pending {
      animation: throb 1s infinite;
    }
  }
  .bold-underline:not(.bold-underline--single-theme){border-color:rgba(255,255,255,0.6)}
  .bold-underline:not(.bold-underline--single-theme):hover{border-color:#fff}
  .tp-logo__text{fill:#fff}
  .tp-widget-loader .tp-logo__text{fill:#191919}
  .tp-logo__text{fill:#fff}
  .tp-widget-loader--loaded{opacity:0}
  .bold-underline:not(.bold-underline--single-theme){border-color:rgba(255,255,255,0.6)}
  .bold-underline:not(.bold-underline--single-theme):hover{border-color:#fff}
  .tp-widget-empty-vertical{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center}
  .tp-widget-empty-vertical__title{font-size:18px;margin:0 0 12px;text-align:center}
  .tp-widget-empty-vertical__stars{margin:0 0 8px;width:150px}
  .tp-widget-empty-vertical__subtitle-wrapper{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}
  .tp-widget-empty-vertical__subtitle{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;font-size:14px;text-align:center}
  .tp-widget-empty-vertical__logo{margin:0 0 5px 5px;width:90px}
  .tp-widget-empty-horizontal{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;height:100%;-ms-flex-pack:center;justify-content:center}
  .tp-widget-empty-horizontal__title{font-size:14px;text-align:center}
  .tp-widget-empty-horizontal__logo{margin:0 0 5px 5px;width:90px}
  .tp-widget-wrapper{font-size:0}
  .tp-widget-trustscore{font-size:16px}
  .tp-widget-stars{padding:0px 0 0;width:96px }
  .tp-widget-rating{font-size:14px;line-height: 1.2}
  .tp-widget-rating span{display:none}
  .tp-widget-logo{width:72px;}
  svg{display:block;width:100%}
  b,strong{
    font-weight:700
  }
  .bold-underline,.tp-widget-empty-vertical__title,.tp-widget-empty-horizontal__title,.tp-widget-trustscore,.tp-widget-rating strong{
    font-family:"Segoe UI","Helvetica Neue","Helvetica","Arial","sans-serif";
    font-weight:500
  }
  .tp-widget-loader,.tp-widget-fallback{
    left:50%;position:absolute;top:50%;transform:translate(-50%, -50%)
  }
  #fallback-logo{display:none}
  .no-svg #fallback-logo{display:inline-block}
  .tp-widget-wrapper{height:100%;margin:0 auto;max-width:750px;position:relative}
  .tp-stars .tp-star__canvas,.tp-stars .tp-star__canvas--half{fill:#dcdce6}
  .tp-stars .tp-star__shape{fill:#fff}
  .tp-stars--1 .tp-star:nth-of-type(-n+1) .tp-star__canvas,.tp-stars--1 .tp-star:nth-of-type(-n+1) .tp-star__canvas--half{fill:#ff3722}
  .tp-stars--2 .tp-star:nth-of-type(-n+2) .tp-star__canvas,.tp-stars--2 .tp-star:nth-of-type(-n+2) .tp-star__canvas--half{fill:#ff8622}
  .tp-stars--3 .tp-star:nth-of-type(-n+3) .tp-star__canvas,.tp-stars--3 .tp-star:nth-of-type(-n+3) .tp-star__canvas--half{fill:#ffce00}
  .tp-stars--4 .tp-star:nth-of-type(-n+4) .tp-star__canvas,.tp-stars--4 .tp-star:nth-of-type(-n+4) .tp-star__canvas--half{fill:#73cf11}
  .tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas,.tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas--half{fill:#00b67a}
  .tp-stars--1--half .tp-star:nth-of-type(-n+2) .tp-star__canvas--half{fill:#ff3722}
  .tp-stars--2--half .tp-star:nth-of-type(-n+3) .tp-star__canvas--half{fill:#ff8622}
  .tp-stars--3--half .tp-star:nth-of-type(-n+4) .tp-star__canvas--half{fill:#ffce00}
  .tp-stars--4--half .tp-star:nth-of-type(-n+4) .tp-star__canvas,.tp-stars--4--half .tp-star:nth-of-type(-n+4) .tp-star__canvas--half{fill:#00b67a}
  .tp-stars--4--half .tp-star:nth-of-type(-n+5) .tp-star__canvas--half{fill:#00b67a}
  .tp-widget-loader,.tp-widget-fallback{max-width:50%;text-align:center;width:400vh}
  .tp-widget-loader svg,.tp-widget-fallback svg{width:100%}
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    .bold-underline,.tp-widget-empty-vertical__title,.tp-widget-empty-horizontal__title,.tp-widget-trustscore,.tp-widget-rating strong{
      font-weight:600
    }
  }
  @media screen and (min-width: 175px){
    .tp-widget-wrapper{font-size:0;text-align:center}
    .tp-widget-trustscore,.tp-widget-stars,.tp-widget-rating,.tp-widget-logo{display:inline-block;vertical-align:middle}
    .tp-widget-trustscore{margin:0 16px 4px 0}
    .tp-widget-stars{margin:0 0 4px}
    .tp-widget-logo{margin-bottom:4px}
    .tp-widget-rating{margin:2px 4px 4px 0}
    .tp-widget-rating span{display:inline}
  }
  @media screen and (max-width: 300px){
    .tp-widget-empty-vertical__subtitle-wrapper{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}
    .tp-widget-empty-vertical__logo{margin:5px 0 0}
    .tp-widget-empty-horizontal{-ms-flex-direction:column;flex-direction:column}
    .tp-widget-empty-horizontal__logo{margin:0}
  }
  @media screen and (min-width: 400px){
    .tp-widget-loader,.tp-widget-fallback{max-width:200px}
  }
  @media screen and (min-width: 420px){
    .tp-widget-logo,.tp-widget-rating{margin-bottom:0}
    .tp-widget-logo{width:80px}
    .tp-widget-stars{margin:0 12px 4px 0;width:110px}
    .widget-info{display:inline-block;margin:0 0 4px;vertical-align:middle}
    .tp-widget-rating{margin-right:8px;vertical-align:middle}
    .tp-widget-trustscore{font-size:18px}
  }
}
@media screen and (max-width: 725px){
  header.site .tp-widget-stars {
    padding-top: 8px;
  }
}
@keyframes throb {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}