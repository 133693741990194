:root {
  --background: #000;
  --surface-1:  #272727;
  --surface-2:  #{$grey-900};
  --surface-3:  #{$grey-800};
  --surface-4:  #{$grey-700};
  --text:       #{$white};
  --text-1:     #{$grey-100};
  --text-2:     #{$grey-200};
  --text-3:     #{$grey-300};
  --text-4:     #{$grey-400};
  --text-5:     #{$grey-500};
  --shadow-1:
    1px 2px 3px -2px rgba(0, 0, 0, 0.5);
  --shadow-2:
    1px 3px 5px -2px rgba(0, 0, 0, 0.3),
    2px 7px 10px -5px rgba(0, 0, 0, 0.25);
}