// extra bits from the other components.scss that AREN'T included in badger-ui
$modal-head-foot-back: rgba($white, 0.06);
$modal-head-edge: rgba($black, 0.5);
$modal-foot-edge: rgba($white, 0.1);
$modal-head-foot-shadow: 0 0 8px rgba($black, 0.2);

a, .hover {
  text-decoration: none;
  color: $blue;
  &:hover {
    color: $orange;
  }
  &:focus {
    text-decoration: none;
  }
}

a.button {
  &:focus {
    text-decoration: none;
  }
}


// labels
@each $name,$color in $colors {
  .label.#{$name} {
    border-color: darkish-color($color);
  }
}

// layout
.tall {
  height: 100%;
  min-height: 100vh;
}
hr {
  margin: 1rem 0;
  border: 0;
  height: 1px;
  background: $line-color;
  &.green {
    background: $green;
  }
}

// typography
h1, h2, h3, h4, h5, h6 {
  font-family: $font-light;
  font-weight: normal;
}
h1 {
  letter-spacing: 1px;
  &.ultra {
    font-family: $font-ultra;
  }
}
h2 {
  letter-spacing: 1px;
}
h3 {
  letter-spacing: 1px;
  font-family: $font-light;
}
h4 {
  font-family: $font-light;
  letter-spacing: 1px;
}
h5 {
  font-size: $size-medium;
  font-family: $font-medium;
  //color: $primary-mid;
  color: $orange;
}
h6 {
  font-family: $font-medium;
  color: $primary-mid;
  color: $green;
}
.bold {
  font-family: $font-medium;
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.underline {
  padding-bottom: 0.2em;
  margin-bottom: 0.2em;
  border-bottom: 1px solid $border-color;
}
.border-left {
  border-left: 1px solid $border-color;
}
.scroll {
  cursor: pointer;
}

p.intro {
  color: $white;
}

ol li {
  margin-top: 1rem;
}


.table tr.baseline td {
  vertical-align: baseline;
}
.table tr.baseline td.top {
  vertical-align: top;
}
.table tr.baseline td.middle {
  vertical-align: middle;
}

.flex.wrap, .flex-wrap {
  flex-wrap: wrap;
}

.icon.transparent {
  color: transparent;
}
.overlay.panel-grey {
  background: rgba(#eee, 0.8);
}
.overlay.dark {
  background: rgba($dark, 0.7);
  color: $white;
}
.overlay.blackish {
  background: rgba($black, 0.7);
  color: $white;
}
.overlay.mar--2 {
  width: calc(100% + 2em);
  height: calc(100% + 2em);
  margin: -1em -1em -1em -1em;
}
.button {
  font-family: $font-medium;
}

/* Works on Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $orange $dark;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background: $dark;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $orange;
    border-radius: 20px;
    border: 1px solid $orange;
  }
}

.alert .body .content {
  flex-basis: 100%;
}

.bg-dark {
  background-color: $dark;
  color: $white;
}
.button.black-border {
  border-color: $black;
}
.modal-overlay {
  //background-color: rgba($white, 0.5);
  background-color: rgba($black, 0.7);
}
.modal {
  border: 3px solid rgba($black, 0.5);
  border: 1px solid $modal-head-foot-back;
  box-shadow: 5px 5px 5px rgba($black, 0.3);
  background: $dark;
  padding: 1rem;
  .close {
    z-index: 1200;
  }
  .modal-title {
    padding: 0.25rem 0.5rem;
    margin: -1rem -1rem 1rem -1rem;
    line-height: 2.2rem;
    box-shadow: $modal-head-foot-shadow;
    background-color: $modal-head-foot-back;
    font-size: $size-largish;
    //border-bottom: 1px solid $modal-head-edge;
  }
  .footer {
    padding: 0.5rem;
    margin: 0 -1rem -1rem -1rem;
    box-shadow: $modal-head-foot-shadow;
    //border-top: 1px solid $modal-foot-edge;
    background-color: $modal-head-foot-back;
  }
  .close {
    background: rgba($orange, 0.5);
    border: 2px solid rgba($white, 0.4);
    color: $black;
    &:hover {
      background: rgba($red, 0.9);
      color: $black;
      // color: dark-color($red);
      border-color: $white;
      box-shadow: 0 0 0 4px rgba($red, 0.5);
    }
  }
}

.revealable {
  &>.head {
    &:hover {
      background-color: $black;
      box-shadow: 0 0 0 1px $option-hover;
    }
  }
  &.plus &>.head .icon {
    margin-right: 0.5rem;
  }
}