.panel.booking.inactive {
  opacity: 0.5;
}

table.invoice_items {
  border: none;
  td, th {
    border: none;
  }
  tr.invoice-item {
    td, th {
      border-bottom: 2px solid $dark;
    }
  }
  .discount td {
    color: $red;
  }
}

div.actions .action {
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    // background-color: $blue;
    color: $orange;
    cursor: pointer;
  }
}

.user-info {
  margin-bottom: 1rem;
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: $dark;
  }
}

.status.label {
  min-width: 6.5em;
  text-align: center;
}