$panel-pad: 1rem;
$panel-half-pad: 0.5rem;
$header-lines: rgba($black, 0.25);

.panel {
  background-color: var(--surface-1);
  color: var(--text);
  overflow: auto;
  overflow: visible;
  margin-bottom: 1rem;
  position: relative;
  h1, h2, h3, h4, h5, h6 {
    color: var(--text-2);
  }
  & > .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-2);
    font-size: $size-largish;
    line-height: 150%;
    font-family: $font-medium;
    font-weight: normal;
    background-color: var(--surface-2);
    //box-shadow: 0 3px 5px rgba($black, 0.02);
    h3 {
      color: var(--text-2);
      font-weight: normal;
    }
    margin: 0;
    padding: 0.25rem $panel-pad;
    user-select: none;
    .status .stack-back  {
      // filter: drop-shadow(1px 2px 2px rgba($black, 0.3));
      filter: drop-shadow(0 0 2px rgba($black, 1));
    }
  }
  &.closeable >h3.title {
    transition: all 0.25s ease-in-out;
    border-bottom: 3px solid var(--background);
    &:hover {
      border-color: $orange;
    }
  }
  .panel-body {
    padding: $panel-pad;
    padding-top: $panel-pad;
    color: $white !important;
  }
  .bib {
    background-color: var(--background);
  }
  .inset {
    background-color: var(--background);
    padding: 0.5rem;
  }
  label {
    color: var(--text-3);
  }
  .panel-body.scroll-x {
    .scrollable {
      text-align: center;
      overflow-x: scroll;
    }
  }
  .panel-body h4.panel-fieldset-caption {
    display: inline-block;
    font-size: $size-smallish;
    line-height: 150%;
    font-weight: bold;
    margin-left: -1rem;
    padding: 0 1rem;
    background-color: rgba($white, 0.1);
    color: $white;
    border-radius: 0 1rem 1rem 0;

  }
  header.panel-header, footer.panel-footer {
    background-color: darkish-color($smoke);
    color: darkest-color($smoke);
    line-height: 150%;
    .label {
      box-shadow: 1px 1px 2px 0 rgba($black, 0.2);
      // margin-top: -0.5rem;
      // margin-bottom: 0.25rem;
    }
    .row {
      border-bottom: 1px solid rgba($black, 0.1);
      &:last-child {
        border-bottom: 0;
      }
      &>div:not(.type) {
        padding-top: 0.2rem;
      }
    }
    .discount {
      .price {
        color: dark-color($red);
      }
    }
    .total {
      background-color: $smoke;
      color: $black;
    }
    .heading {
      background-color: $smoke;
      font-size: $size-smallish;
      border-bottom: 1px solid $header-lines;
    }
  }

  header.panel-header {
    // border-top: 1px solid dark-color($smoke);
    border-bottom: 1px solid $header-lines;
    font-size: $size-smallish;
    line-height: 120%;
    padding: 0.5em 0;
  }
  footer {
    // border-top: 1px solid $header-lines;
    // font-size: $size-smallish;
    // line-height: 150%;
    // font-weight: bold;
    // padding: 0 0 0.25em 0;
    color: var(--text-3);
    &.has-heading {
      border-top: none;
    }
  }
}

@each $name, $color in $colors {
  .panel.#{$name} {
    & > .title {
      background-color: $color;
      color: $black;
    }
    &.closeable > .title:hover {
      background-color: lfh-light-color($color);
    }
  }
}

@media only screen and (max-width: $tablet) {
  .panel > .title {
    font-size: $size-medium;
  }
}
@media only screen and (max-width: $mobile) {
  .panel > .title {
    font-size: $size-smallish;
  }
}
