.hero {
  margin-top: 0rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 3rem;
    font-size: 6em;
    color: $white;
    letter-spacing: 3px;
    line-height: 125%;
    margin: 3rem 0 0 !important;
    padding: 0 2rem;
  }
  &.bssr {
    margin-top: -$header-height;
    padding-top: 168px;
  }
  .lhme {
    color: $green;
    font-size: 2rem;
    line-height: 120%;
    margin: 2rem 0 3rem 0;
    text-shadow: 2px 2px 3px rgba($black, 0.3);
    font-family: $font-light;
    .get-started {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
    .icon {
      margin: 0 0.5rem;
      font-weight: 100;
    }
  }
  .tagline {
    max-width: 75%;
    margin: 1rem auto;
    text-shadow: 2px 2px 3px rgba($black, 0.2);
    h2 {
      font-size: 1.7em;
      margin-top: 1rem;
    }
    h3 {
      font-size: 1em;
      font-family: $font-light;
      line-height: 170%;
    }
  }
  &.tbyb {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
    .bgimg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      animation: 5s forwards fadeinout;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    .lenses-lineart img {
      max-width: 50rem;
      margin-top: -8rem;
    }
  }
  .button.blue {
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  }
}

@keyframes fadeinout {
  0% { opacity: 0; }
  10% { opacity: 100%; }
  90% { opacity: 100%; }
  100% { opacity: 0%; }
}
@keyframes slideinout {
  0% { opacity: 0; transform: translateX(100vw) ; }
  10% { opacity: 100%; transform: translateX(0) ; }
  90% { opacity: 100%; transform: translateX(0) ; }
  100% { opacity: 0; transform: translateX(0) ; }
}

.split-421 {
  display: grid;
  float: none;
  text-align: left;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  font-size: $size-largish;
  color: $grey-100;
  h2.green {
    font-size: $size-large;
    color: $green;
  }
  p {
    line-height: 1.35;
    font-size: $size-smallish;
    margin: 0 0 0.5em 0;
  }
}

.blg {
  border-bottom: 1px solid green;
}

.lens-gurus {
  .id-card-talk {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    .id-card {
      text-align: center;
    }
    .talk {
      h2 {
        margin-top: 8rem;
        font-size: $size-largest;
        line-height: 120%;
        color: $orange;
      }
      p {
        max-width: 20rem;
        font-size: $size-largish;
      }
    }
  }
  .contact-us {
    text-align: center;
    padding: 3rem 0;
    a {
      margin: 0 3rem;
      font-size: $size-larger;
      color: $white;
      &:hover {
        color: $orange;
      }
    }
  }
}


@keyframes pulse {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}


@media only screen and (max-width: $laptop) {
  .hero {
    .lhme {
      .get-started {
        margin-top: 1rem;
        display: block;
      }
    }
  }
  .split-421 {
    grid-template-columns: repeat(2, 1fr);
  }
  .latest-kit .container .product-preview {
    .quote .blurb {
      padding-right: 1rem;
      font-size: $size-largish;
    }
    .new {
      font-size: 4rem;
    }
  }
  .lens-gurus {
    .id-card-talk {
      display: block;
      .id-card {
        text-align: center;
      }
      .talk {
        max-width: 20rem;
        margin: 0 auto;
        h2 {
          margin-top: 1rem;
        }
      }
    }
    .contact-us a {
      display: block;
      margin: 0 0 2rem 0;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .latest-kit .container {
    .latest-wrapper {
      margin: 0 0.5rem;
    }
    .nav {
      justify-content: start;
      padding-top: 2rem;
    }
    .product-preview {
      grid-template-columns: 1fr;
      .quote .blurb {
        padding-right: 0rem;
        font-size: $size-large;
      }
      .lens-name {
        padding-bottom: 1rem;
      }
      .new {
        font-size: 6rem;
        padding-bottom: 1rem;
      }
      .product-pic {
        margin-right: 0;
      }
    }
  }
  .hero.tbyb .lenses-lineart img {
    max-width: 50rem;
    margin-top: -6rem;
    margin-bottom: 2rem;
  }
}