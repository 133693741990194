// field bottom margin
$field-mar-b: 1rem !default;

// field input - should be same "unit" size as buttons
$field-input-back:      $black;
$field-input-fore:      $white;
$field-input-edge:      rgba($white, 0.2);
$field-input-radius:    2px;
$field-input-place:     rgba($white, 0.5);
$saving-color:          $violet;
$saving-edge:           $violet;

// different input states: focussed, changed, valid, invalid
$field-focus-color:     $orange;
$field-pending-color:   $brown;
$field-focus-edge:      $field-focus-color;
$field-focus-fore:      $field-input-fore;
$field-focus-back:      $field-input-back;
$field-focus-place:     $field-input-place;
$field-changed-color:   $orange !default;
$field-changed-edge:    mix($field-changed-color, $white, 30%) !default;
$field-changed-fore:    mix($field-changed-color, $black, 50%) !default;
$field-changed-back:    mix($field-changed-color, $white, 5%) !default;
$field-changed-place:   rgba($field-changed-color, 0.5) !default;
$field-valid-color:     $green !default;
$field-valid-edge:      $field-valid-color;
$field-valid-fore:      $field-input-fore;
$field-valid-back:      $field-input-back;
$field-valid-place:     $field-input-place;
$field-invalid-color:   $red !default;
$field-invalid-edge:    $field-invalid-color;
$field-invalid-fore:    $field-input-fore;
$field-invalid-back:    $field-input-back;
$field-invalid-place:   $field-input-place;
$field-saving-color:    $saving-color !default;
$field-saving-edge:     $saving-color;
$field-saving-fore:     $field-input-fore;
$field-saving-back:     $field-input-back !default;
$field-saving-place:    rgba($field-saving-color, 0.5) !default;

// field labels
$field-label-mar-b:     0.25em !default;
$field-label-fore:      var(--text-3);
$field-label-size:      0.75em !default;
$field-label-line:      1.5 !default;
$field-label-weight:    300 !default;
// calculate the amount of space taken by a label, e.g. for aligning buttons
// note that the margin must be multiplied by 0.75 to account for the smaller
// font-size of the label
$field-label-height: ($field-label-size * $field-label-line) + ($field-label-mar-b * 0.75) !default;

// field help
//$field-help-fore:       rgba($white, 0.8);
//$field-help-fore:       var(--text-2);
$field-help-fore:       red;
$field-help-size:       0.75em !default;
$field-help-line:       1.5 !default;
$field-help-margin:     0.25em 0 0 0 !default;
$focus-box-shadow:      0 0 0 1px $orange;
