//----------------------------------------------------------------------------
// Fonts
//----------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@include font('HnLight', '/fonts/HelveticaNeue-Light');
@include font('HnMedium', '/fonts/HelveticaNeue-Medium');
@include font('HnUltra', '/fonts/HelveticaNeue-UltraLight');

$font-ultra:  (HnUltra, Helvetica, sans-serif) !default;
$font-light:  (HnLight, Helvetica, sans-serif) !default;
$font-medium: (HnMedium, Helvetica, sans-serif) !default;
$font-lato:   'Lato', sans-serif !default;

$font-text: $font-light;
$font-head: $font-ultra;
