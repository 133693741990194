.manufacturers {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem 4rem;
  margin: 2rem 4rem;
  .option {
    color: rgba(white, 0.9);
    .dot {
      height: 16px;
    }
    .dot {
      background-color: $dark;
      width: 12px;
      height: 12px;
      margin: 1rem auto;
      border-radius: 6px;
    }
    &:hover {
      color: white;
      .dot {
        background-color: $orange;
      }
    }
    .selected .dot {
      background-color: $green;
    }
  }
}

.latest-kit .container {
  padding: 2rem 0;
  h2 {
    margin: 3rem 0;
    text-align: center;
  }
  .product-preview {
    display: grid;
    text-align: left;
    padding: 1rem 0;
    grid-template-columns: 2fr 5fr 5fr;
    .new {
      font-size: 6rem;
      text-align: center;
    }
    .quote {
      .blurb {
        padding-right: 1rem;
        font-size: $size-large;
        font-family: $font-light;
        line-height: 125%;
        letter-spacing: 0.02em;
        color: $grey-100;
        p {
          margin: 0;
        }
      }
    }
    .product-pic {
      position: relative;
      // margin-right: 1rem;
    }
    .details {
      margin-top: 2rem;
      margin-right: 1rem;
      .body-type {
        font-size: $size-large;
        margin-bottom: 0.5rem;
        height: 1.5rem;
        display: inline-block;
        margin: 0 auto 0 0;
        &.nikon {
          margin-left: -0.5rem;
        }
      }
      .lens-name {
        font-size: $size-largish;
        font-family: $font-light;
        color: $grey-300;
        line-height: 120%;
      }
    }
  }
  .product-card .picture {
    padding: 3rem;
  }
}

.quotes {
  &.bg-black-trans {
    // background-color: rgba($black, 0.7);
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%);
  }
  .pictures-carousel .carousel.carousel-slider {
    padding-bottom: 4rem;
    .carousel-item {
      margin: 0 6rem;
    }
  }
  .item {
    height: 14rem;
    max-width: 85ch;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .quote {
      font-size: $size-larger;
      line-height: 150%;
    }
    .attrib {
      text-align: right;
      color: $yellow;
      font-style: italic;
      font-size: $size-smallish;
    }
  }
}

.home-banner-container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0rem auto;
}
.home-banner {
  padding: 1rem;
  background-color: rgba($black, 0.3);
  border:1px solid rgba($white, 0.3);
  box-shadow: 1px 2px 6px rgba($black, 0.4);
  backdrop-filter: blur(20px);
}

@media only screen and (max-width: $laptop) {
  .latest-kit .container {
    .product-preview {
      .quote .blurb {
        padding-right: 1rem;
        font-size: $size-largish;
      }
      .new {
        font-size: 4rem;
      }
    }
  }
  .manufacturers {
    gap: 1rem 2rem;
  }
  .quotes {
    .pictures-carousel .carousel.carousel-slider {
      .carousel-item {
        margin: 0 4rem;
      }
    }
    .item {
      .quote {
        font-size: $size-largish;
        line-height: 130%;
      }
    }
  }
}


@media only screen and (max-width: $tablet) {
  .manufacturers {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 3rem;
    .option:last-child {
      grid-column: 1 / 3;
      padding: 0 25%;
    }
  }
  .latest-kit .container {
    .product-preview {
      .new {
        font-size: 4rem;
      }
      .quote {
        margin-top: 1rem;
        .blurb {
          padding-right: 1rem;
          font-size: $size-large;
        }
      }
    }
  }
  .quotes {
    .pictures-carousel {
      .carousel.carousel-slider {
        .carousel-item {
          margin: 0 2.5rem;
          .item {
            .quote {
              font-size: $size-medium;
              line-height: 130%;
            }
          }
        }
        .nav .icon {
          font-size: 2rem !important;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .manufacturers {
    gap: 1rem 2rem;
    margin: 1rem 1rem;
  }
  .latest-kit .container {
    .latest-wrapper {
      margin: 0 0.5rem;
    }
    .nav {
      justify-content: start;
      padding-top: 2rem;
    }
    .product-preview {
      grid-template-columns: 1fr;
      .quote {
        order: 2;
        margin-top: 1rem;
        .blurb {
          padding-right: 0rem;
          font-size: $size-largish;
        }
      }
      .lens-name {
        padding-bottom: 1rem;
      }
      .new {
        font-size: 6rem;
        padding-bottom: 1rem;
      }
      .product-pic {
        order: 1;
        margin-right: 0;
      }
    }
  }
}