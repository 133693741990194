.about {
  section {
    position: relative;
    background-color: var(--surface-1);
    padding: 2rem;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: none;
    }
  }
  .section-title {
    margin-top: 0;
    //color: $orange;
  }
  .section-subtitle {
    color: $orange-500;
    // color: $brown;
  }
  .subtext {
    color: $grey-300;
    letter-spacing: 0.05em;
  }
  .icon-left {
    display: flex;
    gap: 1rem;
    .icon-section {
      width: 8rem;
      padding-top: 2rem;
      font-size: 600%;
      text-align: center;
      flex-shrink: 0;
    }
  }
  .product-pictures {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .pic {
      width: 80px;
      height: 80px;
      padding: 10px;
      border-radius: 40px;
      background-color: white;
      // overflow: hidden;
      box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.5);
      img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
      }
    }
  }
  address {
    //background-color: var(--background);
    //padding: 2rem 3rem;
    font-weight: bold;
    font-style: normal;
  }
  .equation {
    width: 50%;
  }
}

.about .stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0 1rem 0;

  .stat {
    text-align: center;
    .qty {
      color: $orange;
      font-size: 3rem;
      line-height: 1.5;
    }
    .caption {
      color: $white;
      font-size: $size-large;
      line-height: 1.2;
    }
  }
}
img.white-border {
  border: 10px solid $white;
}

.user-card {
  background-color: var(--surface-1);
  // border: 8px solid var(--surface-1);
  display: flex;
  flex-direction: column;
  position: relative;
  header {
    position: relative;
    height: 300px;
    width: 100%;
    text-align: center;
    background-size: cover;
    img.face {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: -75px;
      left: 50%;
      transform: translateX(-50%);
      // margin-top: -80px;
      border: 8px solid var(--surface-1);
      border-radius: 75px;
    }
    // background: top left no-repeat;
  }
  main {
    flex-basis: 100%;
    padding: 90px 2rem 1rem 2rem;
    text-align: center;
    h2 {
      margin: 0;
    }
    h3 {
      margin: 0;
      color: $grey-400;
    }
    .about {
      text-align: left;
      font-size: $size-smallish;
      color: $grey-200;
      // min-height: 280px;
      letter-spacing: 0.05em;
      margin: 1rem 0 2rem 0;
      p {
        margin: 0;
      }
    }
  }
  .dyk {
    margin: 0rem 2rem 1rem 2rem;

    min-height: 4rem;
    h4 {
      color: $white;
      display: inline;
      font-weight: bold;
      letter-spacing: 0.15em;
      font-size: $size-medium;
      margin: 0 0.5rem 0 0;
    }
    font-size: $size-smallish;
    line-height: 1.5;
    color: $blue;
  }
  footer {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    img.mini {
      border-radius: 38px;
      box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.5);
    }
  }
  &.wide {
    // grid-column: 1/3;
    .about {
      columns: 2;
    }
    .dyk {
      margin: 0rem 2rem 1rem 50%;
      padding-left: 0.5rem;
      min-height: 4rem;
      height: auto;
    }
  }
}

.history {
  background-color: var(--surface-1);
  // border: 8px solid var(--surface-1);
  position: relative;
  padding-left: 4rem;
  // vertical stripe
  &::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: $grey-800;
    top: 0;
    bottom: 0;
    left: 2rem;
  }
  .events {
    padding: 1rem;
  }
  .event {
    padding: 1rem 2rem;
    margin: 2rem 2rem 2rem 0;
    position: relative;
    // background-color: var(--surface-2);
    background-color: black;
    border-radius: 0.5rem;
    // box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.5);
    h2 {
      margin: 0;
      color: $grey-100;
    }
    p {
      color: $grey-300;
      margin: 0.5rem 0;
    }
    // arrow
    &::before {
      content: " ";
      height: 0;
      position: absolute;
      top: 22px;
      width: 0;
      z-index: 1;
      left: -24px;
      border: 12px solid $white;
      // border-color: transparent var(--surface-2) transparent transparent;
      border-color: transparent black transparent transparent;
    }
    &::after {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      left: calc(-2rem - 28px);
      background-color: $black;
      border: 4px solid $orange;
      top: 18px;
      border-radius: 50%;
      z-index: 1;
      // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    }
  }
}

@media only screen and (max-width: $desktop) {
  .user-card .dyk {
    margin: 0rem 2rem 1rem 50%;
    padding-left: 0.5rem;
    min-height: 2rem;
    height: auto;
  }
}

@media only screen and (max-width: $laptop) {
  .user-card.wide main .about {
    columns: 1;
  }
  .about .equation {
    width: 66%;
  }
  .about .stats .stat {
    .qty {
      font-size: 2rem;
      line-height: 1.5;
    }
    .caption {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .about .equation {
    width: 100%;
  }
  .user-card, .user-card.wide {
    .dyk {
      margin-left: 2rem;
      padding-left: 0;
      min-height: 2rem;
      height: auto;
    }
  }
  .history {
    padding-left: 3rem;
    &::after {
      left: 1rem;
    }
    .events {
      padding: 1rem 1rem 1rem 0;
    }
    .event {
      padding: 1rem;
      margin: 2rem 1rem 2rem 0;
      h2 {
        font-size: $size-large;
      }
      &::before {
        top: 16px;
        left: -24px;
      }
      &::after {
        width: 20px;
        height: 20px;
        left: calc(-1rem - 22px);
        border: 4px solid $orange;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .about .stats {
    grid-template-columns: 1fr;
  }
}