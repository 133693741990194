$header-lines: rgba($black, 0.25);
$border-width: 2px;
$basket-nav-black: 0 0 2px 1px rgba($black, 0.8);
$basket-nav-border: 5px;
$basket-nav-opacity: 0.7;

//$date-dispatch-back: mix($green-900, $black, 20%);
// $date-dispatch-edge: $green-800;
$date-back-sat:      90%;
$date-back-val:      10%;
$date-edge-sat:      70%;
$date-edge-val:      30%;
$date-dispatch-back: hsl($green-hue,  $date-back-sat, $date-back-val);
$date-dispatch-grad: linear-gradient(90deg, hsl($green-hue, $date-back-sat, $date-back-val) 0%, hsl($yellow-hue, $date-back-sat, $date-back-val + 5%) 100%);
// $date-dispatch-edge: hsl($green-hue,  $date-edge-sat, $date-edge-val);
$date-dispatch-edge: $green-600;
$date-dispatch-img:  $green-700;
$date-starts-back:   hsl($yellow-hue, $date-back-sat, $date-back-val);
$date-starts-grad:   linear-gradient(90deg, hsl($yellow-hue, $date-back-sat, $date-back-val) 0%, hsl($orange-hue, $date-back-sat, $date-back-val + 10%) 100%);
$date-starts-edge:   hsl($yellow-hue, $date-edge-sat, $date-edge-val);
$date-starts-edge:   $yellow-500;
$date-starts-img:    $yellow-600;
$date-ends-back:     hsl($orange-hue, $date-back-sat, $date-back-val);
$date-ends-grad:     linear-gradient(90deg, hsl($orange-hue, $date-back-sat, $date-back-val) 0%, hsl($orange-red-hue, $date-back-sat, $date-back-val + 10%) 100%);
$date-ends-edge:     hsl($orange-hue, $date-edge-sat, $date-edge-val);
$date-ends-edge:     $orange-600;
$date-ends-img:      $orange-700;
$date-return-back:   hsl($red-hue,    $date-back-sat, $date-back-val);
$date-return-grad:   linear-gradient(90deg, hsl($red-hue, $date-back-sat, $date-back-val) 0%, hsl($red-magenta-hue, $date-back-sat, $date-back-val + 10%) 100%);
$date-return-edge:   hsl($red-hue,    $date-edge-sat, $date-edge-val);
$date-return-edge:   $red-700;
$date-return-img:    $red-800;

@mixin basket-nav-selected($color) {
    border-left: 4px solid $color !important;
    background-color: rgba($color, 0.3);
//  box-shadow:
//    $basket-nav-black,
//    0 0 0 $basket-nav-border rgba($color, $basket-nav-opacity) !important;
}

.basket {
  .panel {
    & > header, footer {
      padding: 0.25rem 1rem;
    }
  }
  footer {
    padding-top: 0;
    font-weight: bold;
  }
  .price {
    text-align: right;
    padding-right: 0.5rem;
  }
  .items-panel.panel .alert .title {
    border-bottom: none;
  }
  .item-columns {
    display: grid;
    align-items: center;
    grid-template-columns: 80px 1fr 18rem;
    column-gap: 0.5rem;
    .thumbnail, .type {
      grid-column: 1;
      grid-row-start: 1;
      grid-row-end: 4;
    }
    .prices {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
    .hire-days {
      select {
        margin-top: -0.2rem;
        font-size: $size-smallish;
        padding: 0.1rem 0.5rem !important;
      }
    }
    .price {
      &.strike {
        text-decoration: line-through;
      }
      .strike {
        text-decoration: line-through;
        color: $white;
        font-size: $size-smallish;
        line-height: 130%;
      }
      .discount {
        color: $red;
        // font-size: $size-smallish;
        // line-height: 130%;
      }
      .discounted {
        color: $green;
        font-weight: bold;
      }
    }
    .product {
      grid-column: 2;
      /*
      .warning .icon {
        &:hover {
          color: $red;
          background: rgba($red, $alert-icon-back-opacity);
          border-color: rgba($red, $alert-icon-border-opacity);
        }
      }
      */
    }
    .equipment {
      grid-column: 1 / 3;
    }
    .wide {
      grid-column-start: 2;
      grid-column-end: 5;
      &.equipment {
        grid-column-start: 1;
      }
    }
    .controls {
      grid-column: 2;
    }
    .warnings {
      grid-column: 2;
      padding-right: 0.5rem;
    }
    &.discount {
      color: $red;
    }
    &.discounted {
      color: $green;
    }
  }
  .basket-item {
    // margin-bottom: 1rem;
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
    & > * {
      padding: 0.25em 0;
    }
    .thumbnail {
      text-align: center;
      font-size: 0;
      line-height: 0;
      padding: 0;
      align-self: start;
      background-color: $white;
      img {
        background-color: white;
        padding: 5px;
        // border: 1px solid #ddd;
        display: inline-block;
      }
    }
    .product {
      .name {
        color: $white;
        margin-top: 0.3em;
        line-height: 120%;
        &:hover {
          color: $orange;
        }
      }
    }
    .price {
      color: $white;
      text-align: right;
    }
    .inc {
      color: $white;
      font-weight: bold;
    }
    .button.naked {
      background-color: $black;
      padding-left: 0;
      border: 0;
      text-align: left;
      min-width: 6rem;
      .icon {
        margin-right: 0;
      }
      &:focus {
        border: none;
        box-shadow: none;
        &.red {
          color: $red !important;
        }
        &.green {
          color: $green !important;
        }
      }
    }
    .alert {
      padding: 0.75em;
      margin-bottom: 0.25rem;
    }
  }
  .back-check-empty {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 0.5rem;
    .availability-button {
      text-align: center;
    }
    .empty-button {
      text-align: right;
    }
  }
  .dates {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    .date {
      background-color: $black;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      .weekday {
        margin-top: 0.25rem;
        font-size: $size-smallish;
        color: $white;
      }
      h3 {
        font-size: $size-medium;
        margin: 0;
        font-weight: bold;
        &.red {
          color: $red;
        }
        &.green {
          color: $green !important;
        }
      }
      .icon {
        margin-top: 4rem;
        font-size: 6rem;
      }
      .day {
        font-size: $size-largest;
        margin: 0.25rem 0;
        // color: $black;
        color: $white;
      }
      .month-year {
        display: flex;
        justify-content: center;
        color: $white;
        column-gap: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  .panel-options {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    &.delivery-type,
    &.return-type {
      padding: $border-width;
    }
    &.n1 {
      grid-template-columns: repeat(1, 1fr);
      padding-left: 25%;
      padding-right: 25%;
    }
    &.n2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.n3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.n4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .panel-option {
    background-color: $black;
    position: relative;
    color: var(--text-2);
    padding: 1rem;
    text-align: center;
    opacity: 0.8;
    &.compact {
      padding: 0.5rem;
    }
    h3 {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
    }
    .delivery-option-icon {
      font-size: 8rem;
      color: $blue;
    }
    .hover-overlay {
      display: none;
    }
    .hint {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: $size-small;
      color: $white;
      background-color: $dark;
      padding: 0 0.4rem;
      border-top-left-radius: 2px;
      border-right: none;
      border-bottom: none;
      line-height: 1.3;
    }
    .icontag {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 3rem;
      background-color: rgba($black, 0.1);
      border-top-left-radius: 2px;
      &.premium {
        background-color: $violet;
        color: $white;
        border-color: dark-color($violet);
      }
      &.standard {
        background-color: $blue;
        color: $white;
        border-color: dark-color($blue);
      }
    }
    .courier-svg {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      width: 15%;
      padding: 0.5rem;
      background-color: white;
      font-size: 0;
      line-height: 0;
      svg {
        margin-bottom: 0;
      }
    }
    &.selected {
      opacity: 1;
      color: var(--text-1);
      box-shadow: 0 0 0 $border-width $option-selected;
      .delivery-option-icon {
        color: $green;
      }
      .hint,
      .icontag {
        background-color: $option-selected;
        color: $black;
      }
      &:hover {
        // box-shadow: 0 0 0 2px $black, 0 0 0 6px $option-selected;
        box-shadow: 0 0 0 $border-width $option-hover;
      }
    }
    &:hover {
      user-select: none;
      opacity: 1;
//      box-shadow: 0 0 0 2px $black, 0 0 0 6px $option-hover;
      box-shadow: 0 0 0 $border-width $option-hover;
      .delivery-option-icon {
        color: $orange;
      }
      .hint,
      .icontag {
        background-color: $option-hover;
        color: $black;
      }

      cursor: pointer;
      .hover-overlay {
        display: block;
        &.middle {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.basket .enter-postcode {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $size-largish;
  flex-wrap: wrap;
  gap: 1rem;
  input {
    width: 10rem;
  }
}

.basket .identify {
  .tab {
    background-color: $dark;
    color: $white;
    padding: 1rem;
    border-bottom: 0.5rem solid $black;
    &:first-child {
      margin-right: 0.5rem;
    }
    &:hover {
      color: $white;
      user-select: none;
      border-color: $option-hover;
      .icon {
        color: $option-hover;
      }
    }
    &.selected {
      border-color: $option-selected;
      .icon {
        color: $option-selected;
      }
    }
  }
}
.address {
  line-height: 1.3;
  .fao, .company {
    opacity: 0.8;
  }
  .postcode {
    font-weight: bold;
  }
}

.field.invalid.focus .input,
.field.invalid .input:focus {
  border-color: $field-invalid-edge !important;
  box-shadow: 0 0 0 1px $field-invalid-edge !important;
  background-color: $field-invalid-back !important;
}

.contacts {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  .contacts-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    margin-bottom: 1rem;
  }
  .contact-tile {
    background-color: $black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    .type-icon {
      flex-basis: 3rem;
      .icon {
        font-size: 3rem;
        color: $grey-800;
      }
    }
    .body {
      flex-basis: 100%;
      margin-bottom: 0.5rem;
      .flex {
        display: inline-flex !important;
        width: 100%;
        max-width: 100;
      }
      .text {
        max-width: calc(100% - 4rem);
        .detail {
          margin-top: 0rem;
          overflow: auto;
          overflow-wrap: break-word;
          word-wrap: break-word;
          font-size: $size-largish;
        }
        .location {
          font-size: $size-smallish;
          color: $grey-400;
        }
        margin-right: 1rem;
      }
    }
  }
  .contact {
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid $line-color;
    margin-bottom: 0.5rem;
    &.add {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .contact-detail {
      font-size: $size-medium;
      line-height: 120%;
    }
    .contact-location {
      font-size: $size-smallish;
      color: $white;
    }
    .contact-input {
      // font-size: $size-smallish;
    }
    .contact-action {
      font-size: $size-smallish;
      text-align: right;
    }
    .contact-add {
      // font-size: $size-smallish;
      text-align: right;
    }
  }
}

form .hide-header header.form-header {
  display: none;
}

form .field.non-mono textarea {
  font-family: $font-text;
}

nav.basket-nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 0.5rem 0 1.5rem 0;
  .button {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: left;
    border: 1px solid var(--surface-1);
    //border-left: 4px solid var(--surface-2);
    background-color: var(--surface-1);
    &:focus, &.focus {
      box-shadow: none !important;
    }
    .caption {
      flex-grow: 1;
    }
    &:hover {
      border-color: $option-hover;
    }
    &.selected {
      background-color: $orange;
      color: $black;
    }
    &.completed {
      background-color: $green;
      color: $black;
      &:hover {
        border-color: $green;
      }
    }
    color: $white;
  }
  /*
  .basket-step.active .button {
    @include basket-nav-selected($green);
  }
  .hire-step.active .button {
    @include basket-nav-selected($yellow);
  }
  .checkout-step.active .button {
    @include basket-nav-selected($orange);
  }
  .confirmation-step.active .button {
    @include basket-nav-selected($red);
  }
  */
}

.basket .nav-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

div.field.calendar {
  .inputs {
    position: relative;
  }
  input {
    padding-left: 2.25em;
  }
  .icon-container {
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(2em - 2px);
    height: 2em;
    padding: 0.3em;

    background-color: $dark;
    color: $white;
    text-align: center;
    border-right: 1px solid rgba($white, 0.3);
  }
  .icon {
    font-size: 1.3em;
    line-height: 2em;
  }
  &.pending,
  &.focus {
    .icon-container {
      background-color: $orange-900;
      border-color: $orange;
      color: $orange-400;
    }
  }
  &.valid {
    .icon-container {
      background-color: $green-900;
      border-color: $green;
      color: $green-400;
    }
  }
  &.invalid {
    .icon-container {
      background-color: $red-900;
      border-color: $red;
      color: $red-400;
    }
  }
  ::-webkit-calendar-picker-indicator{
    opacity: 0;
    z-index: 1;
    // border: 2px solid red;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 34px;
    cursor: pointer;
  }
}

.panel.order-summary.open {
  .panel-body {
    padding-bottom: 0;
  }
  footer {
    padding-top: 0;
    .price-headings {
      display: none;
    }
    .total {
      margin-top: 0 !important;
    }
  }
}
.panel.order-summary.closed footer {
  padding-top: 0.5rem;
}

.trust-payments {
  text-align: left;
  svg {
    height: 3rem;
    width: auto;
    max-width: 100%;
  }
}

.payment-panel .panel-body {
  display: flex;
  align-items: flex-end;
  .alert {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.basket .empty-duration-availability {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  & > * {
    flex-basis: 30%;
  }
  .eda-duration {
    text-align: right;
  }
}
@media only screen and (max-width: $tablet) {
  .basket .empty-duration-availability {
    .eda-duration {
      order: -1;
      flex: 1 0 100%;
      text-align: center;
    }
  }
}
/*
@media only screen and (max-width: $mobile) {
  .basket .empty-duration-availability {
    .eda-duration {
      .prompt {
        display: none;
      }
      flex: 1 0 auto;
      text-align: left;
    }
    .eda-empty {
      flex: 1 0 auto;
      text-align: right;
      .button {
        margin-right: 0;
      }
    }
    .eda-availability {
      flex: 1 0 100%;
    }
  }
}
*/
@media only screen and (max-width: 420px) {
  .basket .empty-duration-availability {
    & > * {
      flex: 1 0 100%;
    }
    .eda-duration {
      .prompt {
        display: inline;
      }
      flex: 1 0 100%;
      text-align: center;
    }
    .eda-empty {
      flex: 1 0 100%;
      text-align: center;
      .initial-button {
        width: 100%;
      }
    }
    .eda-availability {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $laptop) {
  .basket {
    .item-columns {
      font-size: $size-smallish !important;
      .warnings {
        grid-column: 2 / 4;
      }
    }
    .dates {
      grid-template-columns: repeat(2, 1fr);
      .date .icon {
        margin-top:2rem;
        font-size: 7rem;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .basket {
    .panel-options.n1 {
      padding-left: 0;
      padding-right: 0;
    }
    .panel-options.n2,
    .panel-options.n3,
    .panel-options.n4 {
      grid-template-columns: 1fr;
    }
    .back-check-empty {
      grid-template-columns: repeat(2, 1fr);
      .availability-button {
        text-align: right;
      }
      .empty-button {
        grid-column: 1/3;
        text-align: center;
      }
    }
    .identify .tab:first-child {
      margin-right: 0;
    }
  }
  nav.basket-nav {
    grid-template-columns: repeat(2, 1fr);
  }
  .contacts .contact {
    .contact-input {
      padding-bottom: 0.5rem;
    }
    .contact-location {
      text-align: right;
    }
  }
  .contacts .contact-type {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $border-color;
  }
  .payment-panel .panel-body {
    display: block;
    .alert {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .basket {
    .item-columns {
      grid-template-columns: 80px 1fr 14em;
      .thumbnail, .type {
        grid-row-start: 1;
        grid-row-end: 5;
      }
      .prices {
        gap: 0;
      }
      .product, .prices, .controls {
        grid-column-start: 2;
        grid-column-end: 4;
        width: 100%;
      }
      .equipment {
        grid-column-start: 1;
        grid-column-end: 4;
        width: 100%;
      }
    }
    .basket-item {
      .thumbnail img {
        width: 80px;
      }
      .button.naked {
        min-width: 0;
      }
    }
    .panel-option .delivery-option-icon {
      font-size: 4rem;
    }
    .alert:not(.revealer) .body-icon {
      display: none;
    }
    .dates {
      font-size: $size-smallish;
      .date .icon {
        margin-top:3rem;
        font-size: 4rem;
      }
    }
  }
  nav.basket-nav {
    grid-template-columns: repeat(1, 1fr);
  }
  .address-notes {
    display: block;
    flex-wrap: wrap;
    & > * {
      flex-basis: 100%;
      flex-shrink: 0;
    }
    .button {
      float: right;
      margin-top: 0.5rem;
    }
  }
  .contacts-panel .field.required .required {
    display: block;
    float: none;
    margin-left: 0;
    font-size: $size-small;
  }
}

@media only screen and (max-width: 500px) {
  .basket {
    .dates {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media only screen and (max-width: 420px) {
  .basket {
    .nav-buttons {
      display: block;
      .button {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
