@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#'+$font-family) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@mixin bar-stack($breakpoint) {
  .bar.stack-#{$breakpoint} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .controls {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 0.25rem 0 0 0;
      .button {
        margin-bottom: 0.25rem !important;
      }
    }
  }
}