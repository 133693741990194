footer.site {
  margin-top: 3rem;
  padding-bottom: 1rem;
  overflow: auto;
  background: url('/images/background/pyramid.gif') top center repeat, rgba($black, 0.8);
  .container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .links-and-icon {
    display: flex;
    align-items: flex-start;
  }
  .links {
    flex-basis: 100%;
    margin: 5rem auto 3rem;
    font-size: $size-largish;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 0.75em;
      }
      li, a {
        color: $white;
        color: $grey-100;
      }
      a {
        text-decoration: none;
        &:hover {
          color: $orange;
        }
      }
    }
  }
  .uk-no-1 {
    padding-top: 2rem;
    font-size: 10rem !important;
    text-align: right;
  }
  .copyright {
    display: flex;
    align-items: center;
    font-size: $size-medium;
    text-align: center;
    color: $grey-400;
    // color: rgba($grey, 0.8);
  }
  .version {
    font-size: $size-smallish;
    text-align: right;
    padding-top: 0.9rem;
    color: rgba($white, 0.4);
  }
}

@media only screen and (max-width: $laptop) {
  footer.site {
    .container {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .links {
      grid-template-columns: repeat(2, 1fr);
      grid-template-columns: 1fr;
      margin-top: 2rem;
    }
    .info {
      display: block;
    }
  }
}
@media only screen and (max-width: $tablet) {
  footer.site {
    .container {
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .version {
      padding-top: 0;
    }
  }
}

@media only screen and (max-width: $mobile) {
  footer.site {
    .uk-no-1 {
      font-size: 8rem !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  footer.site {
    .uk-no-1 {
      font-size: 6rem !important;
      margin-top: 2rem;
      margin-left: -4rem;
    }
  }
}
