.if-debugging {
  display: none;
}
.app.debugging {
  .container {
    border: 1px dashed $blue;
  }
  .trustpilot {
    border: 1px dashed $orange;
  }
  .hero .lhme > *,
  .hero h1.ultra > *,
  .split-421 > *,
  .lens-gurus .container .id-card-talk > *,
  .manufacturers .option,
  .latest-kit .product-preview > *,
  #search .option {
    border: 1px dashed $green;
  }
  .if-debugging {
    display: block;
  }
  p {
    border-top: 1px dotted rgba($white, 0.2);
    border-bottom: 1px dotted rgba($white, 0.2);
  }
  .flex {
    border: 1px dashed $magenta;
  }
  @each $name, $color in $base-colors {
    .debug-#{$name} {
      border: 1px dashed $color;
    }
  }
}