div.terms {
  // padding-left: 60px;
  section {
    padding-left: 6rem;
    // margin-top: 2em;
    // margin-bottom: 4em;
  }
  h3 {
    //color: $orange;
  }
  big {
    float: left;
    width: 60px;
    margin-left: -60px;
    font-size: 120%;
    color: $grey;
  }
  code {
    color: $yellow;
  }
}