@use "sass:color";

.button {
  &.solid {
    // color: $black !important;
    text-shadow: none;
    .icon {
      filter: none;
    }
  }
}

@each $name, $col in $colors {
  .button.#{"" + $name} {
    //background-color: mix($col, $black, 10%);
    background-color: $black;
    border-color: $col;
    color: $col;
    &:hover, &:focus, &.focus {
      border-color: $col;
      background-color: mix($col, $black, 20%);
      text-shadow: 1px 1px 1px rgba(black, 0.2);
      color: color.adjust($col, $saturation: 20%, $lightness: 10%);
    }
    &:focus, &.focus {
      background-color: mix($col, $black, 30%);
      @include outline(rgba($col, 0.6));
    }
  }
  .solid.button.#{"" + $name} {
    background-color: $col;
    border-color: $col;
    color: $black;
    text-shadow: none;
    &:hover, &:focus, &.focus {
      background-color: color.adjust($col, $saturation: 20%, $lightness: 10%);
      color: $black;
      border-color: $col;
      text-shadow: none;
    }
    &:focus, &.focus {
      @include outline(rgba($col, 0.6));
    }
  }
  .solid.button.grey {
    &:hover, &:focus, &.focus {
      background-color: #999;
    }
  }
}
.button.subdued {
  opacity: 0.8;
  background-color: var(--surface-1);
  border-color: var(--surface-1);
  &.blue {
    color: $blue-light;
  }
  &:hover {
    opacity: 1;
  }
}
