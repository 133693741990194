.field {
  color-scheme: dark;
  label {
    color: var(--text-3) !important;
    &.bold, b {
      color: $white;
      font-weight: bold;
    }
    &.big, b {
      font-size: $size-medium;
    }
  }
  .input,
  select {
    background-color: $black;
    border-color: rgba($white, 0.3);
    color: $white;
    -webkit-appearance:none;
    &:focus {
      background-color: $black !important;
      color: $field-focus-color;
    }
  }
  &.pending .input {
    border-color: $field-pending-color !important;
    //box-shadow: 0 0 2px $field-pending-color !important;
    color: $white !important;
  }
  &.focus .input,
  & .input:focus {
    border-color: $field-focus-color !important;
    box-shadow: 0 0 2px $field-focus-color !important;
    color: $white !important;
  }
  &.valid .input {
    border-color: $field-valid-color !important;
    box-shadow: 0 0 2px $field-valid-color !important;
    color: $white !important;
  }
  .help {
    color: var(--text-4) !important;
  }
}
.field-label {
  color: var(--text-3) !important;
}


.select .input {
    background-color: $black;
    color: $white;
    .icon {
      color: rgba($white, 0.5);
    }
}

.input,
textarea,
input:not([type]),
input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="text"],
input[type="file"],
input[type="url"],
.checkbox,
select {
  background-color: $black;
  border-color: rgba($white, 0.3);
  color: $white;
  &:focus {
    background-color: $black !important;
    border-color: $field-focus-color !important;
    box-shadow: 0 0 2px $field-focus-color !important;
    color: $white !important;
  }
}
.checkbox {
  &:focus {
    .icon {
      color: $field-focus-color;
    }
  }
  &.checked {
    border-color: $option-selected !important;
    background-color: $black !important;
    color: $white !important;
    .icon {
      color: $option-selected;
    }
  }
  &.pending {
    border-color: $option-hover !important;
  }
  &:focus:hover,
  &:hover {
    border-color: $option-hover !important;
    background-color: $black;
    color: $white !important;
    .icon {
      color: $option-hover !important;
    }
  }
}
// .checkbox.borderless {
.checkbox, .checkbox.checked, .checkbox.disabled {
  border-color: $black !important;
}


.field .inputs {
  .input-icon, .suffix, .prefix {
    background-color: $dark;
  }
}
.field.focus .input,
.field.focus .textarea,
.field.focus .input-icon,
.field.focus .prefix,
.field.focus .suffix,
.field .input:focus,
.field .textarea:focus
.field input:-webkit-autofill:focus {
  box-shadow: none;
}

.field input:-webkit-autofill {
  box-shadow: 0px 0px 0px 100px $black inset;
  //border-color: #E5DFA1 !important;
}

.field input:-webkit-autofill:focus {
  box-shadow: 0px 0px 0px 100px $black inset, $focus-box-shadow;
}

.field.focus {
  .input-icon, .prefix, .suffix {
    background-color: $field-focus-edge !important;
    color: $black !important;
  }
}

.field.required {
  .required {
      color: $red-700 !important;
  }
  &.valid {
    .required {
      color: $green-600 !important;
    }
  }
}

.input.static {
  background-color: var(--surface-2);
  color: var(--text-3);
}