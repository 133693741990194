select {
  appearance: none;
  -webkit-appearance:none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: $field-input-line !important;
  height: calc(2em + 2px);
  cursor: inherit;
  &::-ms-expand {
    display: none;
  }
  padding-right: 26px;
  background: url('/images/icon/angle-down-8.png') no-repeat 95% 50%;

}

.select.focus,
.select:focus {
  .options {
    margin-top: -1px;
    box-shadow: none;
    .option.selected {
      background-color: $orange;
      color: $black;
    }
  }
}
