@import "alert";
@import "breakpoints";
@import "button";
@import "calendar";
@import "checklist";
@import "colors";
@import "countdown";
@import "darkside";
@import "form";
@import "highlights";
@import "label";
@import "panel";
@import "search";
@import "select";
@import "sidebar";
@import "table";
@import "badger";

a.orange {
  color: $orange;
  &:hover {
    color: $green;
  }
}