$calendar-edge: #444;
$calendar-gap: #444;
$calendar-border: #ccc;
$calendar-split: #888;
$calendar-radius: 2px;
$calendar-weekday-back: $grey-700;
$calendar-weekday-text: $grey-300;
$calendar-weekend-back: $grey-900;
$calendar-weekend-text: $grey-400;
$calendar-hire-weekday-back: $green-400;
$calendar-hire-weekday-text: $black;
$calendar-hire-weekend-back: $green-500;
$calendar-hire-weekend-text: $black;
$calendar-booking-weekday-back: $violet-600;
$calendar-booking-weekday-text: $black;
$calendar-booking-weekend-back: $violet-700;
$calendar-booking-weekend-text: $black;
$calendar-caption-back: $black;
$calendar-caption-text: $grey-100;
$calendar-separator-back: $dark;
$calendar-separator-text: $grey-400;
$calendar-unavailable-back: $red-700;
$calendar-unavailable-text: $red-900;
$calendar-past-weekday-back: $grey-800;
$calendar-past-weekday-text: $grey-600;
$calendar-past-weekend-back: $grey-900;
$calendar-past-weekend-text: $grey-700;
$calendar-today-back: $blue-500;
$calendar-today-text: $blue-200;


table.calendar {
    border-spacing: 2px;
    margin: 0 auto;
}

table.calendar td,
table.calendar th {
    padding:            0.25em;
    margin:             0 !important;
    line-height:        1em;
    font-size:          $size-smallish;
}

table.calendar td.day,
table.calendar th.day {
    position:           relative;
    width:              2em;
    overflow:           visible;
    text-align:         center;
    vertical-align:     middle;
    font-weight:        normal;
    color:              $calendar-weekday-text;
    background-color:   $calendar-weekday-back;
}

table.calendar th.caption {
    background-color:   $calendar-caption-back;
    color:              $calendar-caption-text;
    font-size:          $size-smallish;
    padding:            0.25em 0.5em;
    line-height:        120%;
    min-width:          12em;
    font-weight:        normal;
}

table.calendar th.separator,
table.calendar td.separator {
    background-color:   $calendar-separator-back;
    color:              $calendar-separator-text;
}

table.calendar tbody th.separator,
table.calendar tbody td.separator {
    font-weight: bold;
}

table.calendar tbody th.caption {
    text-align: right;
    padding-right: 5px;
}

table.calendar tbody tr:first-child th.caption {
  border-radius: $calendar-radius 0 0 0;
}

table.calendar tbody tr:last-child th.caption {
  border-radius: 0 0 0 $calendar-radius;
}

table.calendar tbody tr:first-child:last-child th.caption {
  border-radius: $calendar-radius 0 0 $calendar-radius;
}

table.calendar th.month {
    position: relative;
    text-align: center;
}

table.calendar td.weekend {
    background-color:   $calendar-weekend-back;
    color:              $calendar-weekend-text;
}

table.calendar td.booking {
    background-color:   $calendar-booking-weekday-back;
    color:              $calendar-booking-weekday-text;
}
table.calendar td.booking.weekend {
    background-color:   $calendar-booking-weekend-back;
    color:              $calendar-booking-weekend-text;
}
table.calendar td.hire {
    background-color:   $calendar-hire-weekday-back;
    color:              $calendar-hire-weekday-text;
}
table.calendar td.hire.weekend {
    background-color:   $calendar-hire-weekend-back;
    color:              $calendar-hire-weekend-text;
}
table.calendar td.unavailable {
    background: $calendar-unavailable-back url('/images/calendar/unavailable.svg') no-repeat center center;
    color: $calendar-unavailable-text;
    background-image: url('/images/calendar/unavailable.svg') !important;
    background-size: cover;
}
table.calendar .name,
table.calendar .names .day {
    background-color:   $calendar-weekday-back;
    color:              $calendar-weekday-text;
}
table.calendar .name.weekend,
table.calendar .names .weekend {
    background-color:   $calendar-weekend-back;
    color:              $calendar-weekend-text;
}
table.calendar thead th.month {
  border-radius: $calendar-radius $calendar-radius 0 0;
}

/*----------------------------------------------------------------------------
 * Availability
 *--------------------------------------------------------------------------*/
table.calendar .product.caption {
  min-width: 10em;
  line-height: 1.2em;
}
table.calendar .availability .lots {
    background-color:       $green;
    color:                  $dark;
}
table.calendar .availability .lots.weekend {
    background-color:       $green-dark;
    color:                  $black;
}
table.calendar .availability .some {
    background-color:       $orange;
    color:                  $dark;
    font-weight: bold;
}
table.calendar .availability .some.weekend {
    background-color:       $orange-dark;
    color:                  $black;
}
table.calendar .availability .none {
    background-color:       $red;
    color:                  $dark;
    font-weight:            bold;
}
table.calendar .availability .none.weekend {
    background-color:       $red-dark;
    color:                  $black;
}

/*----------------------------------------------------------------------------
 * Indicate past, present and future.
 *--------------------------------------------------------------------------*/
table.calendar-today .past {
  opacity: 0.7;
}
table.calendar .availability .past {
    background-color:       $calendar-past-weekday-back;
    color:                  $calendar-past-weekday-text;
}
table.calendar .availability .past.weekend {
    background-color:       $calendar-past-weekend-back;
    color:                  $calendar-past-weekend-text;
}
table.calendar-today td.today {
    background-color:       $calendar-today-back;
    color:                  $calendar-today-text;
}
table.calendar-today .names th.today {
    background-color:       $calendar-today-back;
    color:                  $calendar-today-text;
}

//--------------------------------------------------------------------------
// Key
//--------------------------------------------------------------------------

.calendar-controls {
  display: grid;
  grid-template-columns: 1fr 11rem 1fr;
  .month-select {
    text-align: center;
    select {
      width: 10rem;
      margin: 0 auto;
    }
  }
  .forward {
    text-align: right;
  }
}
.calendar-keys {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

table.calendar-key {
  .caption {
    background-color: transparent !important;
  }
  .day {
    border-radius: 0;
  }
  .spacer {
    width: 3em;
  }
}

@media only screen and (max-width: $mobile) {
  .availability {
    .month-select {
      flex-basis: 100%;
    }
    .calendar-controls {
      .button {
        padding-right: 0.25em;
        margin-right: 0;
        .caption {
          display: none;
        }
      }
    }
  }
  .calendar-keys {
    display: block;
    text-align: right;
    .calendar-key {
      min-width: 16rem;
      td.caption {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .availability {
    .calendar-controls {
      grid-template-columns: 1fr 1fr;
      .date-control.backward {
        order: 2;
      }
      .date-control.forward {
        order: 3;
      }
      .month-select {
        order: 1;
        grid-column: 1 / 3;
      }
    }
  }
}
