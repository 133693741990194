.xmas {
  svg {
    margin-top: 0rem;
    width: 100%;
    max-width: 400px;
    #book3days {
      animation: 5s infinite xmas-xfade alternate;
    }
    #get3weeks {
      animation: 5s infinite xmas-xfade alternate-reverse;
    }
    #dontmiss, #g3wtext, #b3dtext {
      transition: fill 0.2s ease-in-out;
    }
  }
  &:hover:not(.no-roll) svg {
    #dontmiss, #g3wtext, #b3dtext {
      fill: $green;
    }
  }
  &.no-roll svg {
    margin-top: 0;
    margin-left: -4px;
  }
}

@keyframes xmas-xfade {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

