// NOTE: the order is important - units, colors, typography and styles must come first
@import "colors";
@import "alert";
@import "fonts";
@import "field";
@import "vars";

// fields
$field-mar-b: 0.5em !default;

// labels
$label-radius: 0.2rem;
$label-pad-v:  0.2em;
$label-pad-h:  0.4em;

// buttons
$button-radius: 0;

$header-height: 140px;