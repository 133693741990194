.alert {
  box-shadow: var(--shadow-2);
  .body-icon {
    font-size: 3em;
    padding: 0;
    background: transparent !important;
    border: none;
  }
  &.compact {
    padding: 0.25em 0.5em;
    .icon {
      font-size: 1.5em;
      margin-right: 0.25em;
    }
  }
  &.question {
    color:              $question-text !important;
    background-color:   $question-back;
    border-color:       $question-edge;
    .title {
      color:            $question-head !important;
    }
    .body-icon {
      color: $question-edge;
      background: rgba($question-color, $alert-icon-back-opacity);
      border-color: rgba($question-color, $alert-icon-border-opacity);
    }
    .headline {
      background-color: $question-edge;
    }
    &.headline.revealable:not(.dismissable) .headline:hover {
      background-color: $question-hover;
    }
  }
  &.info, &.question, &.warning, &.success, &.error {
    color: $alert-text-color !important;
    .title {
      font-weight: bold;
    }
    a:not(.button) {
      color: rgba($white, 0.8);
      text-decoration: underline;
      &:hover {
        color: white;
      }
    }
  }
  &.black {
    background-color: $black;
  }
}

.alert.warning.revealer {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  display: inline-block;
  padding: 0.5rem;
  border-width: 1px;
  margin: 0;
  opacity: 0.8;
  background-color: var(--surface-1);
  transition: all 0.5s;
  margin-left: -0.5rem;
  .body {
    align-items: center;
  }
  .body-icon {
    font-size: 1.25rem;
  }
  &.minimal {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  &.icon-on-right {
    .body {
      flex-direction: row-reverse;
    }
    .body-icon {
      margin-left: 1rem;
    }
  }
  &:hover {
    cursor: pointer;
    border-color: $orange;
    opacity: 1;
    background-color: rgba($black, 0.2);
  }
  .title {
    margin-bottom: 0;
    line-height: 1rem;
  }
}
