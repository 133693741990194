svg {
  .fill-orange {
    fill: $orange;
    transition: fill 0.2s ease-in-out;
  }
  &:hover {
    .hover-fill-green {
      fill: $green;
    }
  }
}
.animated-banner {
  svg {
    margin-top: 0rem;
    width: 100%;
    max-width: 400px;
    // border: 1px dashed white;
    #part1, .part1 {
      animation: 5s infinite banner-xfade alternate;
      fill: white;
    }
    #part2, .part2 {
      animation: 5s infinite banner-xfade alternate-reverse;
      fill: white;
    }
    #extra, #part1, #part2, .part1, .part2 {
      transition: fill 0.2s ease-in-out;
    }
  }
  &:hover:not(.no-roll) svg {
    #part1, #part2, #extra {
      fill: $green;
    }
  }
  &.no-roll svg {
    margin-top: 0;
    margin-left: -4px;
  }
}
@media only screen and (max-width: $tablet) {
  .animated-banner {
    svg {
      #cake, #flames {
        transform: translate(-90px, 0);
      }
      #celebrating {
        transform: translate(122px, -20px);
      }
      #take {
        transform: translate(50px, -20px);
      }
      #years, #percent {
        transform: translate(-90px, 15px);
      }
    }
  }
}


@keyframes banner-xfade {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

