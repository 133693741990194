.faqs {
  .section-title {
    //color: $orange;
  }
  .revealable {
    .head {
      padding: 0.25rem 0;
      .icon {
        width: 1.5rem;
      }
      .n {
        display: inline-block;
        color: $grey;
        margin-right: 0;
        width: 2.5em;
      }
      &:hover {
        //background-color: $option-hover;
        //color: $black;
        background-color: transparent;
        color: $option-hover;
        icon, .n {
          //color: $black;
          color: $option-hover;
        }
      }
    }
    &.revealed .head {
      color: $green;
      .n {
        color: $green;
      }
    }
    .body {
      margin-left: 4rem;
      padding-left: 0;
      p:first-child {
        margin-top: 0;
      }
      //border-left: 2px solid $orange;
    }
  }
}