.cookies-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 5rem;
  background-color: rgba($black, 0.8);
  border-top: 1px solid $grey;
  padding: 1rem;
  backdrop-filter: blur(3px);
  z-index: 1001;
  a {
    color: $blue;
    &:hover {
      color: $orange;
    }
  }
  b {
    color: $yellow;
  }
  h2 {
    // color: $orange !important;
    margin-top: 0;
  }
}