.sidebar {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  aside {
    flex-grow: 1;
    flex-basis: 16rem;
    // padding-top: 3.7rem;
    .menu-trigger {
      display: none;
    }
  }
  main {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 65%;
  }
  .menu {
    //background-color: $dark;
    padding: 0;
    border: none;
    a {
      background-color: $dark;
      padding: 0.25rem 0.5rem;
      display: block;
      border: 2px solid transparent;
      color: $grey-200;
      .icon {
        color: $grey-300;
      }
      &:hover {
        border-color: $orange;
        color: $grey-100;
        .icon {
          color: $grey-200;
        }
      }
      &.active {
        border-color: $green;
        color: $white;
        .icon {
          color: $white;
        }
      }
    }
    a + a {
      margin-top: 0.5rem;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .sidebar aside {
    .menu-trigger {
      display: block;
      float: right;
      margin-bottom: 0.5rem;
      margin-top: -3rem;
      &:hover {
        color: $orange;
      }
    }
    .menu {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
}