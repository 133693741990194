.print {
  display: none;
}
@media print {
  body {
    padding: 1rem;
    color: black !important;
  }
  .dark {
    background-color: white !important;
    h1, h2, h3, h4, h5, h6 {
      color: black !important;
    }
  }
  .bg-dark,
  .bg-black,
  .product-info .product-panel,
  .price-add .price div.money,
  .price-add .price div.duration,
  .price-add .price {
    background-color: $white !important;
    color: $black !important;
    border-color: $white;
    padding: 0;
    border: 0;
  }
  .product-info .product-panel {
    .title {
      padding-left: 0;
    }
    .panel-body {
      padding: 0;
      .scroll-x {
        padding: 0;
      }
    }
    table.product-features tr td,
    table.product-features tr th {
      padding: 0.25em 0;
    }
    .price-add {
      justify-content: center;
      align-items: center;
      .price {
        flex-basis: auto;
      }
    }
  }
  button, .button {
    display: none !important;
  }
  header.site {
    background: white;
    height: 80px;
    img {
      display: none;
    }
    img.print {
      display: block;
      height: 40px;
    }
    div.controls {
      display: none;
    }
  }
  footer.site {
    display: none;
  }
  .panel {
    border: 1px solid #ccc;
    background-color: $white !important;
    color: $black !important;
    .title {
      background-color: white;
    }
    header.panel-header {
      background-color: #eee;
      padding: 0;
      .heading {
        background-color: #ddd;
      }
    }
    footer.panel-footer {
      background-color: #eee;
      padding: 0;
      .heading {
        background-color: #ddd;
      }
      .total {
        background-color: #ddd;
      }
    }
  }
  .basket .items .basket-item {
    background-color: white;
  }
  .user-layout {
    .sidebar {
      aside {
        display: none;
      }
    }
  }
  .shadow-1, .shadow-2, .shadow-3 {
    box-shadow: none;
  }
  .print-panel {
    padding: 0 !important;
  }
  h1.product-name {
    font-size: $size-largish;
  }
  .trustpilot {
    display: none;
  }
  table,
  table thead td,
  table thead th,
  table tbody td,
  table tbody th,
  table tfoot td,
  table tfoot th {
    background-color: $white !important;
    color: $black !important;
  }
  .scrollbar {
    scrollbar-color: $white $white;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $white;
      border-color: $white;
    }
  }
  .carousel {
    .nav,
    .control-dots {
      display: none;
    }
  }
  .print-underline {
    border-bottom: 1px solid black;
  }
}