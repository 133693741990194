$border-width: 2px;
$search-input: $blue;
// $search-hover: $option-hover;
// $search-focus: $field-focus-color;
$search-hover: $blue;
$search-focus: $blue;

.search-input {
  max-width: 28em;
  margin: 1rem 0;
  border: 0;
  border-bottom: 2px solid $blue;
  background-color: $black;
  display: flex;
  align-items: center;
  padding: 0.25em 0.25em 0.5em 0;
  .icon {
    font-size: $size-large;
    color: $blue;
    margin: 0;
    &.clear:hover {
      color: $red;
    }
  }
  &:hover {
    border-color: $search-hover;
    .icon {
      color: $search-hover;
    }
  }
  &.focus {
    border-color: $search-focus;
    .icon {
      color: $search-focus;
    }
  }
  input {
    font-size: $size-largish;
    flex-grow: 1;
    border: none;
    background: transparent;
    margin: 0 0.5em;
    color: $white;
    &:focus {
      border: 0 !important;
      outline: none;
      box-shadow: none !important;
    }
  }
}

#search {
  .options {
      margin: 0 -0.5rem;
  }
  .option {
    padding: 0.5rem;
    display: inline-block;
    vertical-align: top;
    .item {
      background-color: $dark;
      // border: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      &:hover {
        // border-color: $orange;
        box-shadow: 0 0 0 $border-width $orange;
      }
      &.selected {
        // border-color: $green;
        box-shadow: 0 0 0 $border-width $green;
      }
    }
  }
}

.body-types {
  padding: 0;
  .option {
    width: 20%;
  }
  .body-type {
    // padding: 3% 5%;
    padding: 4% 25% 2%;
  }
}
.lens-mounts {
  .option {
    width: 50%;
    padding-bottom: 0 !important;
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
  .lens-mount {
    font-size: 0;
    line-height: 0;
    padding: 10% 24%;
  }
}
.categories {
  padding: 0;
  .option {
    width: 25%;
  }
  .category {
    padding: 2% 10%;
    user-select: none;
    span.icon-span {
      width: 4rem;
    }
    .title, .subtitle {
      color: $white;
      font-family: $font-medium;
      line-height: 120%;
      margin: 0;
    }
  }
}
.product_types {
  .option {
    width: 25%;
  }
  .category {
    .title {
      display: flex;
      align-items: center;
      color: $white;
      font-family: $font-medium;
      font-size: $size-largish;
      line-height: 120%;
      .icon {
        margin-right: 1rem;
      }
    }
  }
}

.prices-sort {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  .prices, .sort, .whatsnew {
    display: flex;
    align-items: center;
    gap: 1rem;
    select {
      font-size: $size-small;
    }
  }
}
.browse-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: $laptop) {
  .body-types > .option {
    width: 33.333%;
  }
  .categories .option {
    width: 33.333%;
  }
  .product_types .option {
    width: 50%;
  }
}
@media only screen and (max-width: $tablet) {
  .body-types > .option {
    width: 33.333%;
  }
  .categories .option {
    width: 50%;
  }
  .product_types .option {
    width: 50%;
  }
  .recently-viewed h3 {
    font-size: $size-medium;
  }
}
@media only screen and (max-width: $mobile) {
  .body-types > .option {
    width: 50%;
  }
  .categories .option {
    width: 100%;
  }
  .product_types .option {
    width: 100%;
  }
}