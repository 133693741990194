.socials {
  max-width: 50em;
  margin: 3rem auto 4rem;
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  .icon-links {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    a {
      font-size: $size-largish;
      padding: 1rem 4rem;
      text-decoration: none;
      text-align: center;
      .icon {
        font-size: 6rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.trustpilot {
  padding: 2rem 0;
  margin: 0 auto;
  a {
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
  img {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
}

@media only screen and (max-width: $tablet) {
  .socials {
    max-width: 50em;
    .icon-links {
      a {
        font-size: $size-medium;
        padding: 1rem 1rem;
        .icon {
          font-size: 4rem;
        }
      }
    }
  }
}

